import { API } from '../../../api';
import { IFetchPageQuery } from '../../../IFetchQueryOptions';
import { IPagination } from '../../../IPagination';
import { CaisoSyncOrigin, ICaisoSync } from './interface';

export const syncNow = async (
    type: CaisoSyncOrigin,
    signal?: AbortSignal | null
): Promise<boolean> => { 
    const syncPath = `/scheduling-coordinator/caiso/drrs/sync-now`;

    const response = await API.fetch(syncPath, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ type }),
        signal,
    });

    if (!response.ok) {
        throw new Error(`Failed to initiate sync for ${type}`);
    }

    const respBody: any = await response.json();

    return respBody.success || true;
};

export const fetchCaisoSyncs = async (
    origin: CaisoSyncOrigin,
    pageQuery: Pick<IFetchPageQuery, 'pagination'>,
    signal?: AbortSignal | null
): Promise<IPagination<ICaisoSync>> => {
    let { pagination } = pageQuery;
    const limit = pagination?.pageSize || 10;
    const offset = (pagination?.current ? pagination.current - 1 : 0) * limit;

    const path = `/scheduling-coordinator/caiso/drrs/syncs?${new URLSearchParams({
        offset: offset.toString(),
        limit: limit.toString(),
        origin,
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error(`Cannot get CAISO ${origin} data!`);
    }

    const respBody: any = await response.json();
    const syncs: ICaisoSync[] = Array.isArray(respBody.data)
        ? respBody.data.map((sync: ICaisoSync) => {
              return {
                  id: sync.id,
                  date: sync.date,
                  source: sync.source,
                  status: sync.status,
              };
          })
        : [];

    return {
        meta: {
            total: respBody.meta?.total || 0,
            offset: respBody.meta?.offset || 0,
            limit: respBody.meta?.limit || 0,
        },
        data: syncs,
    };
};
