import { add } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { round } from 'lodash';
import classNames from 'classnames';
import { PeakEventIndexDay } from '../PeakEventIndexDay';
import { isSameDayInTimezone } from 'src/domain/date/date';
import { PeakLoadForecastMarket } from '../PeakLoadForecastMarket';
import './PeakEventIndexWeekView.css';

export type PeakEventIndexWeekViewProps = {
    days: PeakEventIndexDay[];
    market: PeakLoadForecastMarket;
    onChange: (day: PeakEventIndexDay) => void;
}

export const PeakEventIndexWeekView = ({ days, market: plfMarket, onChange }: PeakEventIndexWeekViewProps) => {
    const { timezone } = plfMarket;

    function selectDay(day: PeakEventIndexDay) {
        const eventIndex = day.getPeakEventIndex();
        if (eventIndex === null) return;

        onChange(day);
    }

    function makeDayView(day: PeakEventIndexDay, index: number) {

        const weekDay = formatInTimeZone(day.datetime.toISOString(), timezone, 'EEE');
        const weekDayNum = formatInTimeZone(day.datetime.toISOString(), timezone, 'dd');

        const hourEnding = add(day.datetime, { hours: 1 });
        const hour = formatInTimeZone(hourEnding.toISOString(), timezone, 'HH');

        const eventIndex = day.getPeakEventIndex();
        const eventIndexValue = eventIndex === null ? 'N/A' : round(eventIndex);

        const today = isSameDayInTimezone(new Date(), day.datetime, timezone);

        const dayClass = classNames({
            'peak-event-index-day': true,
            'selected': day.selected,
            'high': day.eventChance() === 'high',
            'medium': day.eventChance() === 'medium',
            'today': today,
            'disabled': eventIndex === null
        });

        return (
            <div className={dayClass} onClick={() => selectDay(day)} key={'day-' + index}>
                <div className='peak-event-index-day--index'>
                    {eventIndexValue}
                </div>
                <div className='peak-event-index-day--date'>
                    <span>{weekDay}</span>&nbsp;
                    <span className='peak-event-index-day-num'>{weekDayNum}</span>
                </div>
                {eventIndex !== null &&
                    <div className='peak-event-index-day--hour'>
                        HE {hour}
                    </div>
                }
            </div>
        )
    }

    return (
        <div className='peak-event-index-week-view'>
            {days.map(makeDayView)}
        </div>
    )
}
