import { format, getTimezoneOffset, toZonedTime } from 'date-fns-tz';
import { formatInTimeZone } from 'date-fns-tz';

export const DEFAULT_DATE_FORMAT = 'hh:mm a MMM dd, yyyy';

export function getComputerFormattedTime(date: Date, dateFormat = DEFAULT_DATE_FORMAT): string {
    return format(date, dateFormat);
}

export function getUtcFormattedTime(date: Date, dateFormat = DEFAULT_DATE_FORMAT): string {
    return formatInTimeZone(date, 'UTC', dateFormat);
}

export function getTimezoneFormattedTime(date: Date, timeZone?: string, dateFormat = DEFAULT_DATE_FORMAT): string {
    if (!timeZone) return '';

    return formatInTimeZone(date, timeZone, dateFormat);
}

/**
 * Apply timezone offset (in milliseconds)
 * to the provided Date
 */
export function withTimezoneOffset(date: Date, tz: string) {
    const offsetMs = getTimezoneOffset(tz, date);

    return new Date(date.valueOf() + offsetMs);
}

export const makeDateKey = (date: Date) => {
    const year = date.getFullYear();
    const month = `${date.getMonth() + 1}`.padStart(2, '0');
    const day = `${date.getDate()}`.padStart(2, '0');

    return `${year}-${month}-${day}`;
};

export function isSameDayInTimezone(date: Date, date2: Date, timezone: string) {
    const dateInTz = toZonedTime(date, timezone);
    const dateInTzKey = makeDateKey(dateInTz);

    const date2InTz = toZonedTime(date2, timezone);
    const date2InTzKey = makeDateKey(date2InTz);

    return dateInTzKey === date2InTzKey;
}
