import { useMemo } from 'react';
import { endOfMonth, subMonths, startOfMonth } from 'date-fns';

export const useDataAvailabilityStatsQueryDates = () => {
    return useMemo(() => {
        const startDate = startOfMonth(subMonths(new Date(), 11));
        const endDate = endOfMonth(new Date());

        return [startDate, endDate];
    }, []);
};
