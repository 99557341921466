import { FC, useEffect, useState } from 'react';
import { IFilterOption, useSidebar } from '../context/SidebarContext';
import Tag from 'antd/lib/tag';
import { Tooltip } from 'antd/lib';
import './sidebarActiveFilters.css';

interface ISidebarActiveFilters {
    filters: Record<string, string | string[]> | undefined;
    handleClose?: (filter: IFilterOption, event: React.MouseEvent<HTMLElement>) => void;
}
export const SidebarActiveFilters: FC<ISidebarActiveFilters> = ({ filters, handleClose }) => {
    const [selectedFilters, setSelectedFilters] = useState<IFilterOption[]>([]);
    const { getSelectedFiltersToDisplay } = useSidebar();

    useEffect(() => {
        if (filters) {
            setSelectedFilters(getSelectedFiltersToDisplay(filters));
        }
    }, [filters]);

    return (
        <div className="filters-container">
            {selectedFilters.length !== 0
                ? selectedFilters.map(filter => {
                      const textValueSplited = filter ? filter.textValue.split(',') : '';
                      const countOfOptions = textValueSplited.length;

                      return (
                          <Tooltip title={countOfOptions > 1 ? filter.textValue : ''} key={filter.key}>
                              <Tag closeIcon onClose={event => handleClose && handleClose(filter, event)}>
                                  {filter.title}:{' '}
                                  {countOfOptions > 1
                                      ? `${textValueSplited[0]} + ${countOfOptions - 1} more `
                                      : filter.textValue}
                              </Tag>
                          </Tooltip>
                      );
                  })
                : null}
        </div>
    );
};
