import { startOfDay, isBefore, isAfter, addDays } from 'date-fns';

interface IDateRange {
    start: Date;
    end: Date;
}

const DAYS_IN_WEEK = 7;

export function splitDateRangeByChunks(startDate: Date, endDate: Date, chunkSizeInDays = DAYS_IN_WEEK): IDateRange[] {
    const dateChunks: IDateRange[] = [];
    let currentDate = startOfDay(startDate);

    while (isBefore(currentDate, endDate)) {
        const chunkEndDate = addDays(currentDate, chunkSizeInDays - 1);

        // Ensure the chunk end date does not exceed the specified end date
        if (isAfter(chunkEndDate, endDate)) {
            dateChunks.push({ start: currentDate, end: endDate });
            break;
        }

        dateChunks.push({ start: currentDate, end: chunkEndDate });

        currentDate = addDays(chunkEndDate, 1);
    }

    return dateChunks;
}
