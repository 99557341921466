import { endOfDay, addMonths, addWeeks, eachMonthOfInterval, startOfDay, startOfMonth, format, add } from 'date-fns';
import { PAGE_ACTION } from '../../domain/commonConst';
import isEmpty from 'lodash/isEmpty';
import { AppAbilityType } from '../../components/ability/ability';
import { CAISO_DRRS_VIEW, CAISO_MODE, CAISO_SIBR_VIEW } from '../interface';
import { IUseAuth } from '../../domain/auth/useAuth';
import { ICompany } from '../../domain/company/interface';
import { addDays, eachDayOfInterval } from 'date-fns';

export enum RESOURCE_PAGE_ACTION {
    DOWNLOAD_XML = 'download_xml',
}

export type RESOURCE_ACTION = RESOURCE_PAGE_ACTION | PAGE_ACTION;

export interface IDownloadXMLModal {
    onCancel: Function;
    resources: ICaisoResourceData[];
    monthsPeriod: Date[];
}

export interface ICaisoResourceData {
    id: string;
    name: string;
    pmin: number;
    pmax: number;
    months: {
        [key: string]: {
            mw: number;
            heStart: number;
            heEnd: number;
            lmp: number;
            program_id?: number;
        };
    };
}

export interface ICaiso {
    companyId: number | null;
    auth?: IUseAuth;
    view: CAISO_MODE;
    handleViewMode: (view: CAISO_MODE) => any;
    companies?: ICompany[];
}

export interface ICaisoPageState {
    pagination: { pageSize: number; current: number; total?: number };
    sorter: { field: string; order: 'ascend' | 'descend' };
    search: string;
    filter: Record<string, any>;
}

export const month = {
    current: format(new Date(), 'yyyy/MM'),
    next: format(add(new Date(), { months: 1 }), 'yyyy/MM'),
};

export const defaultDate = {
    start: startOfDay(new Date()),
    end: endOfDay(addWeeks(new Date(), 1)),
};

export const generateDatePeriod = () => {
    const currentMonth = startOfMonth(new Date());
    const yearAhead = addMonths(currentMonth, 3);
    const rangeOfMonth = eachMonthOfInterval({ start: currentMonth, end: yearAhead });

    return rangeOfMonth.map(month => month);
};

export const generateDayPeriod = () => {
    const currentDay = startOfDay(new Date());
    const dayAhead = addDays(currentDay, 5);
    const rangeOfDay = eachDayOfInterval({ start: currentDay, end: dayAhead });

    return rangeOfDay.map(day => day);
};

export const validateRequiredMWValue = (mwValues: ICaisoResourceData['months'], months: string[]) => {
    let error: string[] = [];

    if (isEmpty(mwValues)) {
        error.push(`MW data should exist.`);
        return error;
    }

    months.forEach(
        month =>
            (mwValues[month] === undefined || mwValues[month] === null || mwValues[month].mw === 0) &&
            error.push(`MW data for ${month} should exist.`)
    );

    return error;
};

export const caisoViewOptions = (userAbility: AppAbilityType) => [
    {
        title: 'SIBR',
        value: CAISO_MODE.SIBR,
        tooltip: 'SIBR',
        isVisible: userAbility.can('read', 'CaisoSIBR'),
    },
    {
        title: 'DRRS',
        value: CAISO_MODE.DRRS,
        tooltip: 'DRRS',
        isVisible: userAbility.can('read', 'CaisoDRRS'),
    },
];

export const caisoDrrsViewOptions = (userAbility: AppAbilityType) => [
    {
        title: 'Locations',
        value: CAISO_DRRS_VIEW.LOCATIONS,
        tooltip: 'Locations',
        isVisible: userAbility.can('read', 'CaisoDRRS'),
    },
    {
        title: 'Registrations',
        value: CAISO_DRRS_VIEW.REGISTRATIONS,
        tooltip: 'Registrations',
        isVisible: userAbility.can('read', 'CaisoDRRS'),
    },
];

export const caisoSibrViewOptions = (userAbility: AppAbilityType) => [
    {
        title: 'Overview',
        value: CAISO_SIBR_VIEW.OVERVIEW,
        tooltip: 'Overview',
        isVisible: userAbility.can('read', 'CaisoSIBR'),
    },
    {
        title: 'Configuration',
        value: CAISO_SIBR_VIEW.CONFIGURATION,
        tooltip: 'Configuration',
        isVisible: userAbility.can('read', 'CaisoSIBR'),
    },
];