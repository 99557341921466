import { IAccount } from '../account/interface';
import { IFetchPageQuery } from '../IFetchQueryOptions';
import { PAGE_ACTION } from '../commonConst';

export enum SPECIFIC_COMPANY_ACTION {
    EDIT_BRANDING = 'edit_branding',
}
export type COMPANY_ACTION = SPECIFIC_COMPANY_ACTION | PAGE_ACTION;

export enum COMPANY_TYPE {
    CUSTOMER_COMPANY,
    CONTROL_PROVIDER,
    UTILITY_CUSTOMER,
}

interface ICompanyApiCredentials {
    copeland_key: string;
    copeland_key_error?: string;
}

export interface ICreateCompany {
    company_name: string;
    type: COMPANY_TYPE;
    utility_names: string[];
    company_vertical?: string;
    api_credentials?: ICompanyApiCredentials | null;
    utility_id?: string;
    iso?: string;
    account_ids?: string[];
}

export interface IUpdateCompany {
    company_id: number;

    company_name: string;
    type: COMPANY_TYPE;
    utility_names: string[];
    company_vertical?: string;
    api_credentials?: ICompanyApiCredentials | null;
    utility_id?: string;
    iso?: string;
    account_ids?: string[];
}

export interface ICompany {
    company_id: number;
    company_name: string;
    company_date_created?: any | null;
    type: COMPANY_TYPE;
    utility_names: string[];
    company_vertical?: string;
    api_credentials?: ICompanyApiCredentials | null;

    /**
     * one of the bellow 2 fields (but not both)
     * could be set for a company type=Utility Customer
     */
    utility_id?: string;
    iso?: string;

    sites_count?: {
        opened: number;
        closed: number;
    };
    accounts?: IAccount[];
}

export const COMPANY_VERTICAL = [
    'Agriculture',
    'Big Box Retail',
    'Cold Storage',
    'Crypto/Datacenter',
    'Education',
    'Hotel',
    'Internal',
    'Manufacturing',
    'Medical',
    'Multi Family',
    'Office',
    'Pumping',
    'Residential',
    'Restaurant',
    'Small Box Retail',
    'Supermarket',
    'Worship',
    'Gas Station',
];

export interface IFetchCompanyPageQuery extends IFetchPageQuery { }

export interface IFetchCompanyQuery {
    company_id: number;
}