import { EChartsOption } from 'echarts';
import { endOfDay, startOfDay } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import { FC } from 'react';
import { Market } from 'src/domain/market-prices/interface';
import { useWeatherQuery } from 'src/domain/weather/queries';
import { IWeather, IWeatherLocation } from 'src/domain/weather/interface';
import { calculateTooltipPosition, CHART_DATA_COLOR, ChartSettings, formatChartTooltip, makeChartAreasSeries, makeLineSeries } from './chart';
import { PlfChart } from './PlfChart';
import { InfoIcon } from '../InfoIcon';
import './Chart.css';

type RealFeelChartProps = {
    date: Date;
    market: Market;
    loadZone: string;
    timezone: string;
    location: IWeatherLocation;
    settings: ChartSettings;
}

export const RealFeelChart: FC<RealFeelChartProps> = ({ date, market, loadZone, timezone, location, settings }) => {

    const start = fromZonedTime(startOfDay(date), timezone);
    const end = fromZonedTime(endOfDay(date), timezone);

    const {
        isFetching,
        data,
    } = useWeatherQuery({
        longitude: location.longitude,
        latitude: location.latitude,
        start,
        end
    }, {
        keepPreviousData: true,
        staleTime: 1 * 60 * 1000 // 1h 
    });

    const areaSeries = makeChartAreasSeries(settings);

    const dataForecastSeries = makeLineSeries(
        'Forecasted',
        toSeriesData(data?.forecast),
        { color: CHART_DATA_COLOR, type: 'dashed' },
        start, end,
        settings.timeframe
    );

    const dataActualSeries = makeLineSeries(
        'Actual',
        toSeriesData(data?.history),
        { color: CHART_DATA_COLOR, type: 'solid' },
        start, end,
        settings.timeframe
    );

    const series: EChartsOption['series'] = [
        ...areaSeries,
        ...dataForecastSeries,
        ...dataActualSeries
    ];

    const option: EChartsOption = {
        tooltip: {
            trigger: 'axis',
            padding: 0,
            borderColor: '#ffffff',
            axisPointer: {
                type: 'line',
                lineStyle: {
                    type: 'solid'
                }
            },
            position: (point, params, dom, rect, size) => calculateTooltipPosition(point, size.contentSize),
            formatter: (params) => formatChartTooltip(params, timezone, '°', location)
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: '{value}°'
            },
        },
        series: series
    };

    return (
        <div className='peak-load-forecast-base-chart'>
            <div className='peak-load-forecast-chart-title'>
                RealFeel
                <InfoIcon tooltip='Combines temperature, humidity, and wind into a single value' />
            </div>
            <PlfChart
                style={{ height: '320px', width: '100%' }}
                showLoading={isFetching}
                option={option}
            />
            <div className='chart-timezone'>{timezone}</div>
        </div>
    );
}

type SeriesData = [Date, number][];

function toSeriesData(data: IWeather[] | undefined): SeriesData {
    return data?.length ? data.map(it => [new Date(it.datetime), it.temperatureFeelsLikeF]) : [];
}
