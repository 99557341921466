import { useEffect, useState } from 'react';
import { useAuth } from 'src/domain/auth/useAuth';

export function useSidebarEnabled() {
    const auth = useAuth()!;
    const [sidebarEnabled, setSidebarEnabled] = useState(false);

    useEffect(() => {
        const isDisabled = localStorage.getItem('enable_sidebar') === 'false';

        const _isEnersponseUser = auth?.user?.company_id === 1 || auth?.user?.isAdmin();
        if (_isEnersponseUser) {
            setSidebarEnabled(true);
        }

        if (!isDisabled && !_isEnersponseUser) {
            setSidebarEnabled(false);
        }
    }, [auth]);

    return { sidebarEnabled };
}
