import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Collapse, Checkbox, Radio, Input, GetProps, Switch, Form } from 'antd';
import './sidebarFilter.css';
import { ColumnFilterItem } from 'antd/es/table/interface';
import { isArray, isNumber, omit } from 'lodash';
import { SidebarFilterType, useSidebar } from '../context/SidebarContext';

const { Panel } = Collapse;
const { Search } = Input;
type SearchProps = GetProps<typeof Input.Search>;

type CustomColumnFilter = {
    fullText?: string;
} & ColumnFilterItem;

export interface ISidebarFilter {
    rootKey: string;
    header: string;
    subHeader?: string;
    dataKey?: string;
    defaultValue?: any;
    options: CustomColumnFilter[];
    filterMultiple?: boolean;
    filterType?: SidebarFilterType;
    onChange?: (key: string, value: any) => void;
}

const SidebarFilter: React.FC<ISidebarFilter> = ({
    header,
    subHeader,
    defaultValue,
    onChange,
    dataKey,
    options = [],
    filterMultiple = true,
    filterType = SidebarFilterType.CHECKBOX,
}) => {
    const [searchValue, setSearchValue] = useState<string | null>();
    const [expanded, setExpanded] = useState(true);
    const [activeKeys, setActiveKeys] = useState(header ? [header] : []);
    const [height, setHeight] = useState();
    const { updateConfig, config } = useSidebar();

    const targetRef = useRef(null);

    const filteredOptions = useMemo(() => {
        let _options = options?.map(option => {
            return {
                label: option.fullText || option.text,
                value: String(option.value),
                ...omit(option, ['text', 'value']),
            };
        });

        if (searchValue) {
            _options = _options.filter((option: any) => option?.label?.toLowerCase()?.indexOf(searchValue) !== -1);
        }

        return _options;
    }, [options, searchValue]);

    const onSearch: SearchProps['onChange'] = target => {
        const value = target.target.value || '';

        if (value?.length >= 2) {
            setSearchValue(value.toLowerCase().trim());
        }

        if (!value) {
            setSearchValue(null);
        }
    };

    // cast value to strings, since they come as strings from the query params
    // will be easier to handle everything as string
    const useDefaultValues = useMemo(() => {
        let value = defaultValue;
        if (isArray(defaultValue)) {
            value = defaultValue.map(String);
        }

        if (isNumber(defaultValue)) {
            value = String(value);
        }

        return value;
    }, [defaultValue]);

    const onCollapseChange = (item: string[]) => {
        const currentConfig = config['defaultPanelFilter'] ?? {};
        let defaultPanelFilter: Record<string, Record<string, string>> = {
            defaultPanelFilter: {
                ...currentConfig,
                [convertHeaderToFilterKey(header)]: header === item[0] ? 'true' : 'false',
            },
        };
        updateConfig(defaultPanelFilter);

        setActiveKeys(item);
    };

    useEffect(() => {
        setExpanded(Boolean(activeKeys.length));
    }, [activeKeys.length]);

    const callback = useCallback((entries: any) => {
        const entry = entries[0];
        if (entry) {
            const { height } = entry.contentRect;
            setHeight(height);
        }
    }, []);

    useEffect(() => {
        if (targetRef.current) {
            const observer = new ResizeObserver(callback);
            observer.observe(targetRef.current);

            return () => {
                observer.disconnect();
            };
        }
    }, [callback]);

    const minHeightValue = useMemo(() => {
        let _height = 250;
        if (options.length === 0 || options.length <= 3) {
            _height = 60;
        }
        if (options.length >= 4 && options.length <= 5) {
            _height = 130;
        }

        return _height;
    }, [options]);

    const convertHeaderToFilterKey = (header: string) => {
        return `panel.filter.${header.replace(' ', '_')}`;
    };

    useEffect(() => {
        const filterKey = config['defaultPanelFilter'];
        if (filterKey) {
            const panelFilterActive = filterKey[convertHeaderToFilterKey(header)];
            setActiveKeys(panelFilterActive === undefined || panelFilterActive === 'true' ? [header] : []);
        } else {
            setActiveKeys([header]);
        }
    }, [config, header]);

    return (
        <Collapse
            bordered={false}
            expandIconPosition="end"
            rootClassName="sidebar-filter"
            defaultActiveKey={header}
            activeKey={activeKeys}
            onChange={onCollapseChange}
        >
            <Panel header={header} key={header}>
                {options.length > 8 && (
                    <div style={{ position: 'relative', marginBottom: '12px' }}>
                        <Search className="search-filter" placeholder="Search" allowClear onChange={onSearch} />
                    </div>
                )}
                <div
                    className={`filters-content-container ${!expanded ? 'no-resize' : ''}`}
                    ref={targetRef}
                    style={{
                        minHeight: minHeightValue,
                        height,
                    }}
                >
                    {filterMultiple && filterType === SidebarFilterType.CHECKBOX && (
                        <Form.Item name={dataKey}>
                            <Checkbox.Group
                                rootClassName="checkbox-filter-container"
                                options={filteredOptions}
                                defaultValue={useDefaultValues}
                                onChange={selection => onChange?.(dataKey || '', selection)}
                            />
                        </Form.Item>
                    )}

                    {!filterMultiple && filterType === SidebarFilterType.CHECKBOX && (
                        <Form.Item name={dataKey}>
                            <Radio.Group
                                name={dataKey || 'radio-container'}
                                rootClassName="radio-filter-container"
                                options={filteredOptions}
                                defaultValue={useDefaultValues}
                                onChange={selection => onChange?.(dataKey || '', selection.target.value)}
                            />
                        </Form.Item>
                    )}

                    {filterType === SidebarFilterType.TOGGLE && (
                        <Form.Item name={dataKey} className="sidebar-filter-form-item" label={subHeader || ''} valuePropName="checked">
                            <Switch onChange={selection => onChange?.(dataKey || '', selection)} />
                        </Form.Item>
                    )}
                </div>
            </Panel>
        </Collapse>
    );
};

export default SidebarFilter;
