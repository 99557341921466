import { FC, useMemo } from 'react';
import Select, { SelectProps } from 'antd/lib/select';
import { IWeatherLocation } from 'src/domain/weather/interface';

export type PeakLoadForecastLocationSelectorProps = SelectProps & {
    locations: IWeatherLocation[];
    value?: string;
}

export const PeakLoadForecastLocationSelector: FC<PeakLoadForecastLocationSelectorProps> = ({ locations, value, ...props }) => {
    const data = locations;

    if (!value) {
        value = data?.[0] ? `${data[0].latitude}:${data[0].longitude}` : null
    }

    const programTypeOptions = useMemo(() => data?.map((item: IWeatherLocation) => ({
        label: `${item.city}, ${item.state}`,
        value: `${item.latitude}:${item.longitude}`,
    })), [data]);

    return (
        <Select
            showSearch
            autoClearSearchValue
            optionFilterProp='label' // to search by label not value
            placeholder='Select Type'
            options={programTypeOptions}
            size='large'
            value={value}
            {...props}
        />
    );
};
