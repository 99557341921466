import InfoCircleOutlined from '@ant-design/icons/lib/icons/InfoCircleOutlined';
import { Tooltip } from 'antd/lib';
import { CSSProperties, FC } from 'react';

export const InfoIcon: FC<{ tooltip: string; style?: CSSProperties }> = ({ tooltip, style }) => {
    return (
        <Tooltip title={tooltip} >
            <InfoCircleOutlined style={{ fontSize: '20px', lineHeight: '24px', color: '#797979', padding: '0 10px', ...style }} />
        </Tooltip>
    )
}
