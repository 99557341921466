import React from 'react';
import { ICompany } from '../company/interface';
import { DataQualityReportPeriod } from './interface';

export interface IReadingsAvailabilityStatsContextProps {
    company: ICompany | null;
    companies: ICompany[];
    isCompaniesLoading: boolean;
    startDate: Date;
    endDate: Date;
    months: Date[];
    period: DataQualityReportPeriod;
}

export const ReadingsAvailabilityStatsContext = React.createContext<IReadingsAvailabilityStatsContextProps>(
    {} as IReadingsAvailabilityStatsContextProps
);

export const useReadingsAvailabilityStatsContext = () =>
    React.useContext<IReadingsAvailabilityStatsContextProps>(ReadingsAvailabilityStatsContext);
