import { add, startOfHour } from 'date-fns';

export class DateRange {
    readonly start: Date;
    readonly end: Date;
    readonly selected: Date;

    constructor(start: Date, selected?: Date) {
        this.start = startOfHour(start);
        this.end = add(this.start, { days: 6 });

        if (!selected) selected = new Date(start);
        this.selected = selected;

        if (this.selected.valueOf() < start.valueOf() || this.selected.valueOf() > this.end.valueOf()) {
            this.selected = new Date(start);
        }
    }
}
