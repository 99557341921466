import { format, getUnixTime } from 'date-fns';
import { API } from '../api';
import { exportCSVData } from '../common/exportCSVData';
import { dateToUtcLocale } from '../common/timeHelpers';
import { DATE_FORMAT_CSV } from '../commonConst';
import { getEventsData } from '../event/getEventsDataService';
import { IAnalyticsChartsData } from '../site/interface';
import { splitDateRangeByChunks } from '../splitDateRangeByChunks';
import { getEnergyReadings } from './getEnergyReadings';
import { ENERGY_USAGE_TYPE, getUsageData } from './getUsageData';
import { IFetchSitesEnergyDataParams } from './interface';
import { isValidDate } from './utils';

const DEFAULT_START_DATE = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
const DEFAULT_END_DATE = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);

export const fetchSitesEnergyUsageForCharts = async (
    { siteIds, date }: IFetchSitesEnergyDataParams,
    signal?: AbortSignal | null
): Promise<IAnalyticsChartsData> => {
    try {
        const startDate: Date = isValidDate(date.start) ? new Date(date.start) : DEFAULT_START_DATE;
        const endDate = isValidDate(date.end) ? new Date(date.end) : DEFAULT_END_DATE;

        const response = await API.fetch(
            `/energy/usage?${new URLSearchParams({
                start: '' + getUnixTime(startDate),
                end: '' + getUnixTime(endDate),
                siteIds: siteIds.join(','),
                include: 'performance,events',
            })}`,
            {
                ...(signal && { signal }),
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        const data = await response.json();

        return transformSitesUsageDataForCharts(data);
    } catch (error: any) {
        throw new Error(error?.message || `Cannot get sites energy data!`);
    }
};

// need to split request by weeks because of large amount of data for server to process
export const exportSitesEnergyDataByChunks = async ({
    siteIds,
    date,
}: IFetchSitesEnergyDataParams): Promise<string> => {
    const DAYS_IN_WEEK = 7;
    const weeklyChunks = splitDateRangeByChunks(date.start, date.end, DAYS_IN_WEEK);

    let csvString = '';
    let currentChunkIndex = 0;
    for await (const weeklyChunk of weeklyChunks) {
        const response = await API.fetch(
            `/energy/usage?${new URLSearchParams({
                start: '' + getUnixTime(dateToUtcLocale(weeklyChunk.start)),
                end: '' + getUnixTime(dateToUtcLocale(weeklyChunk.end)),
                siteIds: siteIds.join(','),
                include: 'performance,market-prices',
                format: 'csv',
            })}`,
            {
                headers: {
                    Accept: 'text/csv;charset=UTF-8',
                },
            }
        );

        let csvData = await response.text();

        // remove table headers from chunks except first
        if (currentChunkIndex > 0) {
            const headersEndIndex = csvData.indexOf('\n');
            csvData = csvData.slice(headersEndIndex + 1);
        }

        csvString = csvString.concat(csvData + '\n');
        currentChunkIndex++;
    }

    return csvString;
};

export const exportSitesEnergyData = async ({ siteIds, date }: IFetchSitesEnergyDataParams): Promise<void> => {
    try {
        const csvData = await exportSitesEnergyDataByChunks({ siteIds, date });
        const filename = `sites-energy-data--${format(date.start, DATE_FORMAT_CSV)}--${format(date.end, DATE_FORMAT_CSV)}.csv`;
        exportCSVData(csvData, filename);
    } catch (error: any) {
        throw new Error(error?.message || `Cannot export data!`);
    }
};

export const uploadEnergyFile = async (file: File, source: 'user-upload' | 'sharepoint-archive') => {
    try {
        const formData = new FormData();
        formData.append('file', file, file.name);
        const response = await API.fetch(`/data/inbox/upload?source=${source}`, {
            method: 'post',
            body: formData,
        });

        if (response.ok) {
            return;
        }

        await response.json();
    } catch (error: any) {
        throw new Error(error?.message || `Cannot upload file!`);
    }
};

function transformSitesUsageDataForCharts(data: any): IAnalyticsChartsData {
    const eventsChartsData = getEventsData(data);
    const readingsChartsData = getEnergyReadings(data);
    const baselineChartsData = getUsageData(data, ENERGY_USAGE_TYPE.BASELINE);
    const performanceChartsData = getUsageData(data, ENERGY_USAGE_TYPE.PERFORMANCE);

    return {
        energyData: readingsChartsData,
        energyBaselineData: baselineChartsData,
        energyPerformanceData: performanceChartsData,
        eventData: eventsChartsData,
    };
}