import { Market } from 'src/domain/market-prices/interface';

export class PeakLoadForecastMarket {
    readonly name: Market;
    readonly loadZone: string;
    readonly timezone: string;
    readonly isSupported: boolean;

    constructor(market: Market, loadZone: string) {
        this.name = market;
        this.loadZone = loadZone;

        /** in the future, when more markets are supported - we may have different timezones here */
        this.timezone = 'EST';
        this.isSupported = this._isSupported();
    }

    private _isSupported() {
        const supportedMarkets = [Market.ISONE];
        return supportedMarkets.includes(this.name?.toLowerCase() as Market);
    }
}
