import Select from 'antd/lib/select';
import FieldTimeOutlined from '@ant-design/icons/lib/icons/FieldTimeOutlined';
import Typography from 'antd/lib/typography';
import isNull from 'lodash/isNull';

import { COMPANY_TYPE, ICompany } from '../../../domain/company/interface';
import { useCompanies } from './CompanyContext';
import { recentCompanies } from './recentCompanies';
import { filterCompaniesByType } from './companyHelpers';
import { filterSelectorOption } from '../selectorHelpers';
import './GlobalCompanySelector.css';

const toCompanyOptions = (companies: ICompany[], recent: number[]) => {
    const options = new Array(companies.length);
    const recentMap: any = {};

    recent.forEach((r, index) => (recentMap[r] = index));

    let position = recent.length;
    companies.forEach(company => {
        const recentPosition = recentMap[company.company_id];

        if (typeof recentPosition === 'undefined') {
            options[position] = {
                label: company.company_name,
                value: company.company_id,
                key: company.company_id,
                recent: false,
                description: company.company_name,
            };
            position++;
        } else {
            options[recentPosition] = {
                label: company.company_name,
                value: company.company_id,
                key: company.company_id,
                recent: true,
                description: company.company_name,
            };
        }
    });

    return [{ label: 'All', value: null, key: null }, ...options.filter(option => option)];
};

export const GlobalCompanySelector = () => {
    const { companyId, companies, handleCompany } = useCompanies()!;
    const customerCompaniesForOption = filterCompaniesByType(companies, COMPANY_TYPE.CUSTOMER_COMPANY);
    const lastSelectedCompanies = recentCompanies.getRecentCompanies();
    const options = toCompanyOptions(customerCompaniesForOption, lastSelectedCompanies);

    const onCompanyChange = (value: any) => {
        if (isNull(value)) {
            handleCompany(value);
        } else {
            const selectedCompany = companies.find((c: ICompany) => c.company_id === value)!;
            selectedCompany.company_id && recentCompanies.setRecentCompany(selectedCompany.company_id);
            handleCompany(selectedCompany?.company_id);
        }
    };

    return (
        <Select
            size="large"
            placeholder="Please select company"
            value={companyId as any}
            onChange={onCompanyChange}
            className="global-company-selector"
            style={{
                width: '200px',
                background: 'color-mix(in srgb, var(--ant-color-primary) 10%, transparent)',
                borderRadius: 8,
            }}
            variant="borderless"
            showSearch
            filterOption={filterSelectorOption}
            data-cy="global-company-selector"
            popupMatchSelectWidth={false}
            dropdownStyle={{
                maxWidth: '300px',
            }}
            options={options}
            optionRender={option => {
                return (
                    <>
                        {option.data.recent && (
                            <FieldTimeOutlined className="recent-icon" style={{ marginRight: '5px' }} />
                        )}
                        <Typography.Text>{option.label}</Typography.Text>
                    </>
                );
            }}
        />
    );
};
