import { IFetchAlertTrackQuery, IFetchAlertTrackUsersQuery } from './interface';
import { ITrackingAlert, ITrackingUser } from '../event/interface';
import { API } from '../api';
import isNil from 'lodash/isNil';

export const fetchTrackingAlerts = async (
    params: IFetchAlertTrackQuery,
    signal?: AbortSignal
): Promise<ITrackingAlert[]> => {
    let { companyId = null, search, start, end, is_opened, is_confirmed, user_ids, event_statuses, sorter } = params;

    if (sorter && (!sorter.field || !sorter.order)) {
        sorter = {};
    }

    const path = `/alert/tracking?${new URLSearchParams({
        ...(companyId && { companyId: companyId.toString() }),
        ...(search && { search }),
        ...(start && { start: start.toISOString() }),
        ...(end && { end: end.toISOString() }),
        ...(!isNil(is_opened) && { is_opened }),
        ...(isNil(is_confirmed) ? {} : { is_confirmed }),
        ...(user_ids && { user_ids: user_ids.toString() }),
        ...(event_statuses && { event_statuses }),
        ...(sorter?.field && { sortField: sorter.field, sortOrder: sorter.order }),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();
    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || 'Cannot fetch alert tracks logs');
};

export const fetchAlertTrackingUsers = async (
    params: IFetchAlertTrackUsersQuery,
    signal?: AbortSignal | null
): Promise<ITrackingUser[]> => {
    const { companyId } = params;

    const path = `/alert/tracking/users?${new URLSearchParams({
        ...(companyId && { companyId: companyId.toString() }),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot get site utilities!`);
};
