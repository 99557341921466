import Select from 'antd/lib/select';
import App from 'antd/lib/app';
import { IUtility } from '../../../domain/utilities/interface';
import { useUtilityListQuery } from '../../../domain/utilities/queries';
import { filterSelectorOption } from '../selectorHelpers';
import './UtilitySelector.css';

interface IUtilitySelector {
    onChange: (value: string, option: any) => void;
    value: string | null;
    zip?: string;
}

interface IUtilityOption {
    label: string;
    value: string;
    suggested: boolean;
    key: string;
    description: string;
}

const findSuggestions = (utilities: IUtility[], zip: string) => {
    let suggestedUtility: string[] = [];
    utilities.forEach(utility => {
        if (utility.zip.includes(zip)) {
            suggestedUtility.push(utility.name);
        }
    });

    return suggestedUtility;
};

const toUtilityOptions = (utilities: IUtility[], zip: string) => {
    const suggestedUtility = findSuggestions(utilities, zip);
    let options: IUtilityOption[] = [];

    utilities.forEach(utility => {
        const utilityName = utility.name;
        const isSuggestedUtility = suggestedUtility.includes(utilityName);

        if (isSuggestedUtility) {
            options.unshift({
                value: utilityName,
                label: utilityName,
                key: utilityName,
                suggested: true,
                description: utilityName,
            });
        } else {
            options.push({
                value: utilityName,
                label: utilityName,
                key: utilityName,
                suggested: false,
                description: utilityName,
            });
        }
    });

    return options;
};

export const UtilitySelector = ({ value, onChange, zip = '' }: IUtilitySelector) => {
    const { notification } = App.useApp();
    const { data, isLoading, isError, error } = useUtilityListQuery({});
    const utilities = data || [];
    const options = toUtilityOptions(utilities, zip);

    if (isError) {
        notification.error({ key: 'utilities', message: error?.message || 'Cannot load utilities list' });
    }

    return (
        <Select
            value={value}
            onChange={onChange}
            size="large"
            placeholder="Please select Utility"
            className="utilities-selector"
            allowClear
            showSearch
            filterOption={filterSelectorOption}
            style={{ width: '100%' }}
            loading={isLoading}
            options={options}
        />
    );
};
