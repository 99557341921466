import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../query-client';
import { IWeatherForecastDaily, OpenWeatherProvider } from './openWeather';
import { IWeatherLocation, IWeatherResult, WeatherType } from './interface';
import { API } from '../api';
import { orderBy } from 'lodash';

const openWeatherProvider = new OpenWeatherProvider();

export const useForecastWeatherQuery = (query: { lat: number; lng: number }, options = {}) => {
    return useQuery<IWeatherForecastDaily[], Error>({
        queryKey: [QueryKey.WEATHER_FORECAST, query],
        queryFn: () => openWeatherProvider.getForecast(query.lat, query.lng),
        refetchOnWindowFocus: false,
        ...options,
    });
};

/*** Weather from our API */

const fetchWeatherLocations = async (): Promise<IWeatherLocation[]> => {
    const response = await API.fetch(`/weather/locations`, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody.data;
    }

    throw new Error(respBody?.error?.message || `Cannot get weather locations!`);
};

export interface IGetWeatherQuery {
    latitude: number;
    longitude: number;
    start: Date;
    end: Date;
}

const fetchWeather = async (q: IGetWeatherQuery): Promise<IWeatherResult> => {
    const query = new URLSearchParams();
    query.set('latitude', q.latitude.toString());
    query.set('longitude', q.longitude.toString());
    query.set('start', q.start.toISOString());
    query.set('end', q.end.toISOString());

    const response = await API.fetch(`/weather?${query.toString()}`, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return mapWeather(respBody.data);
    }

    throw new Error(respBody?.error?.message || `Cannot get weather!`);
};

type WeatherApiResponse = {
    forecast: {
        latitude: number;
        longitude: number;
        datetime: Date;
        type: WeatherType;
        temperatureF: number;
        temperatureFeelsLikeF: number;
        solarRadiationWpsm: number;
    }[];
    history: {
        latitude: number;
        longitude: number;
        datetime: Date;
        type: WeatherType;
        temperatureF: number;
        temperatureFeelsLikeF: number;
        solarRadiationWpsm: number;
    }[];
};

function mapWeather(data: WeatherApiResponse): IWeatherResult {
    let forecast = data.forecast.map(it => {
        return { ...it, datetime: new Date(it.datetime).toISOString() };
    });

    let history = data.history.map(it => {
        return { ...it, datetime: new Date(it.datetime).toISOString() };
    });

    forecast = orderBy(forecast, ['datetime'], ['asc']);
    history = orderBy(history, ['datetime'], ['asc']);

    return {
        forecast,
        history,
    };
}

export const useWeatherLocationsQuery = (options = {}) => {
    return useQuery<IWeatherLocation[], Error>({
        queryKey: [QueryKey.WEATHER_LOCATIONS],
        queryFn: () => fetchWeatherLocations(),
        refetchOnWindowFocus: false,
        ...options,
    });
};

export const useWeatherQuery = (query: IGetWeatherQuery, options = {}) => {
    return useQuery<IWeatherResult, Error>({
        queryKey: [QueryKey.WEATHER, query],
        queryFn: () => fetchWeather(query),
        refetchOnWindowFocus: false,
        ...options,
    });
};
