import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../query-client';
import { ITrackingAlert, ITrackingUser } from '../event/interface';
import { IFetchAlertTrackQuery, IFetchAlertTrackUsersQuery } from './interface';
import { fetchAlertTrackingUsers, fetchTrackingAlerts } from './index';

export const useAlertTrackListQuery = (query: IFetchAlertTrackQuery = {}, options = {}) => {
    return useQuery<ITrackingAlert[], Error>({
        queryKey: [QueryKey.ALERT_TRACKS, query],
        queryFn: ({ signal }) => fetchTrackingAlerts(query, signal),
        refetchOnMount: 'always',
        ...options,
    });
};

export const useAlertTrackingUsersListQuery = (query: IFetchAlertTrackUsersQuery = {}, options = {}) => {
    return useQuery<ITrackingUser[], Error>({
        queryKey: [QueryKey.ALERT_TRACKING_USERS, query],
        queryFn: ({ signal }) => fetchAlertTrackingUsers(query, signal),
        refetchOnMount: 'always',
        ...options,
    });
};
