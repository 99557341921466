import Slider from 'antd/lib/slider'
import React, { FC, useEffect } from 'react';
import _ from 'lodash';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Button from 'antd/lib/button';
import { EyeInvisibleOutlined, EyeOutlined, HolderOutlined } from '@ant-design/icons';
import { useLocalStorage } from 'src/domain/useLocalStorage';
import './PeakLoadForecastCustomizeView.css';

export type PeakLoadForecastCustomizeSettings = {
    timeframe: [number, number];
    layers: {
        sunriseSunset: { visible: boolean; order: number },
        event: { visible: boolean; index: number }
    },
    order: {
        peakLoad: { visible: boolean; order: number },
        peakHours: { visible: boolean; index: number },
        realFeel: { visible: boolean; index: number },
        lmp: { visible: boolean; index: number },
        solarIndex: { visible: boolean; index: number },
        systemLoad: { visible: boolean; index: number },
    }
}

type PeakLoadForecastCustomizeViewProps = {
    onChange: (settings: PeakLoadForecastCustomizeSettings) => void
}

export const PeakLoadForecastCustomizeView: FC<PeakLoadForecastCustomizeViewProps> = ({ onChange }) => {

    const myRef: React.RefObject<HTMLInputElement> = React.createRef();
    const [settings, setSettings] = useLocalStorage<PeakLoadForecastCustomizeSettings>('peak-load-forecast', {
        timeframe: [1, 24],
        layers: {
            sunriseSunset: { visible: true, order: 0 },
            event: { visible: true, index: 1 }
        },
        order: {
            peakLoad: { visible: true, order: 0 },
            peakHours: { visible: true, index: 1 },
            realFeel: { visible: true, index: 2 },
            lmp: { visible: true, index: 3 },
            solarIndex: { visible: true, index: 4 },
            systemLoad: { visible: true, index: 5 },
        }
    });

    useEffect(() => {
        console.info('PeakLoadForecastCustomizeView settings changed:', settings);
        onChange(settings);
    }, [settings])

    function toggleVisibility(subPath: string) {
        const path = `${subPath}.visible`;
        const value = !!_.get(settings, path);

        setSettings({ ..._.set(settings, path, !value) });
    }

    function onTimeFrameChange(timeframe: number[]) {
        setSettings({ ...settings, timeframe: timeframe as [number, number] });
    }

    return (
        <div className='peak-load-forecast-customize-view'>
            <div className='customize-view-title'>Customize View</div>
            <div >
                <div className='customize-view-subtitle'>Timeframe</div>
                <div ref={myRef} style={{ position: 'relative' }}>
                    <Slider
                        className='customize-view--timeframe'
                        range
                        min={1} max={24}
                        defaultValue={settings.timeframe}
                        tooltip={{
                            open: true,
                            autoAdjustOverflow: false,
                            placement: 'bottom',
                            getPopupContainer: (triggerNode: HTMLElement) => myRef.current!
                        }}
                        onChangeComplete={onTimeFrameChange}
                        styles={{
                            track: {
                                background: '#5a88a5',
                            },
                            handle: {
                                background: '#5a88a5',
                            }
                        }}
                    />
                </div>
            </div>
            <div>
                <div className='customize-view-subtitle'>Layers</div>
                <Row justify='space-between' gutter={8} style={{ padding: 0 }}>
                    <Col>Sunrise/Sunset</Col>
                    <Col>
                        <ToggleVisibilityButton
                            value={settings.layers.sunriseSunset.visible}
                            onChange={() => toggleVisibility('layers.sunriseSunset')}
                        />
                    </Col>
                </Row>
                {/* <Row justify='space-between' gutter={8} style={{ padding: 0 }}>
                    <Col>Event</Col>
                    <Col>
                        <ToggleVisibilityButton
                            value={settings.layers.event.visible}
                            onChange={() => toggleVisibility('layers.event')}
                        />
                    </Col>
                </Row> */}
            </div>
            <div>
                <div className='customize-view-subtitle'>Order</div>
                <Row justify='space-between' gutter={8} style={{ padding: 0 }}>
                    <Col><ChangeOrderIcon />Peak Load</Col>
                    <Col>
                        <ToggleVisibilityButton
                            value={settings.order.peakLoad.visible}
                            onChange={() => toggleVisibility('order.peakLoad')}
                        />
                    </Col>
                </Row>
                <Row justify='space-between' gutter={8} style={{ padding: 0 }}>
                    <Col><ChangeOrderIcon />Peak Hours</Col>
                    <Col>
                        <ToggleVisibilityButton
                            value={settings.order.peakHours.visible}
                            onChange={() => toggleVisibility('order.peakHours')}
                        />
                    </Col>
                </Row>
                <Row justify='space-between' gutter={8} style={{ padding: 0 }}>
                    <Col><ChangeOrderIcon />RealFeel</Col>
                    <Col>
                        <ToggleVisibilityButton
                            value={settings.order.realFeel.visible}
                            onChange={() => toggleVisibility('order.realFeel')}
                        />
                    </Col>
                </Row>
                {/* <Row justify='space-between' gutter={8} style={{ padding: 0 }}>
                    <Col><ChangeOrderIcon />LMP</Col>
                    <Col>
                        <ToggleVisibilityButton
                            value={settings.order.lmp.visible}
                            onChange={() => toggleVisibility('order.lmp')}
                        />
                    </Col>
                </Row> */}
                <Row justify='space-between' gutter={8} style={{ padding: 0 }}>
                    <Col><ChangeOrderIcon />Solar Index</Col>
                    <Col>
                        <ToggleVisibilityButton
                            value={settings.order.solarIndex.visible}
                            onChange={() => toggleVisibility('order.solarIndex')}
                        />
                    </Col>
                </Row>
                <Row justify='space-between' gutter={8} style={{ padding: 0 }}>
                    <Col><ChangeOrderIcon />System Load</Col>
                    <Col>
                        <ToggleVisibilityButton
                            value={settings.order.systemLoad.visible}
                            onChange={() => toggleVisibility('order.systemLoad')}
                        />
                    </Col>
                </Row>
            </div>

        </div>
    )
}

const ChangeOrderIcon = () => <HolderOutlined style={{ color: '#bbc3c3', margin: '0 5px 0 0', cursor: 'grab' }} />

const ToggleVisibilityButton = ({ value, onChange }: { value: boolean; onChange: () => void }) => {
    return <Button
        icon={value ? <EyeOutlined /> : <EyeInvisibleOutlined style={{ color: 'rgba(187, 195, 195, 1)' }} />}
        onClick={() => { onChange() }}
        type='text'
    />
}