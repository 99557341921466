import { useQuery } from '@tanstack/react-query';
import { orderBy } from 'lodash';
import { QueryKey } from '../../query-client';
import { API } from '../api';
import { IPeakDays, IPeakEventIndex, IPeakLoadResult } from './interface';
import { Market } from '../market-prices/interface';

export interface IGetPeakLoadQuery {
    start: Date;
    end: Date;
    market: Market;
    loadZone: string;
}

const fetchPeakEventIndex = async (q: IGetPeakLoadQuery): Promise<IPeakEventIndex[]> => {
    const query = new URLSearchParams();
    query.set('market', q.market.toLowerCase());
    query.set('loadZone', q.loadZone.toUpperCase());
    query.set('start', q.start.toISOString());
    query.set('end', q.end.toISOString());

    const response = await API.fetch(`/peak-load-forecast/peak-event-index?${query.toString()}`, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody.data;
    }

    throw new Error(respBody?.error?.message || `Cannot get peak event index!`);
};

export const usePeakEventIndexQuery = (query: IGetPeakLoadQuery, options = {}) => {
    return useQuery<IPeakEventIndex[], Error>({
        queryKey: [QueryKey.PEAK_EVENT_INDEX, query],
        queryFn: () => fetchPeakEventIndex(query),
        refetchOnWindowFocus: false,
        ...options,
    });
};

const fetchPeakLoad = async (q: IGetPeakLoadQuery): Promise<IPeakLoadResult> => {
    const query = new URLSearchParams();
    query.set('market', q.market.toLowerCase());
    query.set('loadZone', q.loadZone.toUpperCase());
    query.set('start', q.start.toISOString());
    query.set('end', q.end.toISOString());

    const response = await API.fetch(`/peak-load-forecast/peak-load?${query.toString()}`, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return mapPeakLoad(respBody.data);
    }

    throw new Error(respBody?.error?.message || `Cannot get peak load!`);
};

export const usePeakLoadQuery = (query: IGetPeakLoadQuery, options = {}) => {
    return useQuery<IPeakLoadResult, Error>({
        queryKey: [QueryKey.PEAK_LOAD, query],
        queryFn: () => fetchPeakLoad(query),
        refetchOnWindowFocus: false,
        ...options,
    });
};

type PeakLoadApiResponse = {
    forecast: {
        datetime: string;
        loadMw: number;
        market: Market;
        loadZone: string;
    }[];
    history: {
        datetime: string;
        loadMw: number;
        market: Market;
        loadZone: string;
    }[];
};

function mapPeakLoad(data: PeakLoadApiResponse): IPeakLoadResult {
    let forecast = data.forecast.map(it => {
        return { ...it, datetime: new Date(it.datetime).toISOString() };
    });

    let history = data.history.map(it => {
        return { ...it, datetime: new Date(it.datetime).toISOString() };
    });

    forecast = orderBy(forecast, ['datetime'], ['asc']);
    history = orderBy(history, ['datetime'], ['asc']);

    return {
        forecast,
        history,
    };
}

export interface IGetPeakHoursQuery {
    datetime: Date;
    market: Market;
    loadZone: string;
}

const fetchPeakDays = async (q: IGetPeakHoursQuery): Promise<IPeakDays[]> => {
    const query = new URLSearchParams();
    query.set('market', q.market.toLowerCase());
    query.set('loadZone', q.loadZone.toUpperCase());
    query.set('datetime', q.datetime.toISOString());

    const response = await API.fetch(`/peak-load-forecast/peak-days?${query.toString()}`, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody.data;
    }

    throw new Error(respBody?.error?.message || `Cannot get peak hours!`);
};

export const usePeakDaysQuery = (query: IGetPeakHoursQuery, options = {}) => {
    return useQuery<IPeakDays[], Error>({
        queryKey: [QueryKey.PEAK_DAYS, query],
        queryFn: () => fetchPeakDays(query),
        refetchOnWindowFocus: false,
        ...options,
    });
};
