import App from 'antd/lib/app';
import Calendar, { CalendarProps } from 'antd/lib/calendar';
import { endOfMonth, isSameMonth, startOfMonth } from 'date-fns';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React, { FC } from 'react';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import { Button } from 'antd';
import LeftOutlined from '@ant-design/icons/lib/icons/LeftOutlined';
import RightOutlined from '@ant-design/icons/lib/icons/RightOutlined';
import classNames from 'classnames';
import { preparePeakEventIndexDays } from '../PeakEventIndexDay';
import { usePeakEventIndexQuery } from 'src/domain/peak-load-forecast/queries';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';
import { PeakLoadForecastMarket } from '../PeakLoadForecastMarket';
import { isSameDayInTimezone, makeDateKey } from 'src/domain/date/date';
import './PeakLoadForecastCalendar.css';

type PeakLoadForecastCalendarProps = {
    date: Date;
    market: PeakLoadForecastMarket;
    onChange: (date: Date) => void;
}

export const PeakLoadForecastCalendar: FC<PeakLoadForecastCalendarProps> = ({ date, market: plfMarket, onChange }) => {
    dayjs.extend(utc);

    const { notification } = App.useApp();

    const start = fromZonedTime(startOfMonth(date), plfMarket.timezone);
    const end = fromZonedTime(endOfMonth(date), plfMarket.timezone);

    const peakEventIndexRes = usePeakEventIndexQuery({
        start, end, market: plfMarket.name, loadZone: plfMarket.loadZone,
    }, {
        keepPreviousData: false
    });

    if (peakEventIndexRes.isError) {
        notification.error({
            key: 'plf-peak-event-index-error',
            message: peakEventIndexRes.error.message || 'Cannot load peak event index!',
        });
    }

    const selectedWeekDays = preparePeakEventIndexDays({ start, end, selected: date }, peakEventIndexRes.data, plfMarket);

    const cellRender: CalendarProps<Dayjs>['fullCellRender'] = (cellDate, info) => {
        if (info.type !== 'date') return info.originNode;

        if (isSameMonth(date, cellDate.toDate()) === false) {
            return undefined;
        }

        const today = isSameDayInTimezone(new Date(), cellDate.toDate(), plfMarket.timezone);

        const selectedDay = selectedWeekDays?.find(day => {
            const dateTimeInTimezone = toZonedTime(day.datetime, plfMarket.timezone);
            const key = `${dateTimeInTimezone.getFullYear()}-${dateTimeInTimezone.getMonth() + 1}-${dateTimeInTimezone.getDate()}`;
            const cellKey = `${cellDate.get('year')}-${cellDate.get('month') + 1}-${cellDate.get('date')}`;
            const isSame = key === cellKey;
            // if (isSame) {
            //     console.info(`day.datetime=${day.datetime.toISOString()}, cell=${cellDate.utc().toISOString()}, key=${key}`);
            // }
            return isSame;

        });
        const isInSelectedWeek = false;

        const dayClass = classNames({
            'peak-load-forecast-calendar--day': true,
            'today': today,
            'high': !!(selectedDay && selectedDay.eventChance() === 'high'),
            'medium': !!(selectedDay && selectedDay.eventChance() === 'medium'),
        });

        // console.info(`CAL cellDate.toDate()=${cellDate.utc().toISOString()}, cellDate.get('date')=${cellDate.get('date')}, selectedDay=${selectedDay?.datetime.toISOString()}, eventChance=${selectedDay?.eventChance()}`);

        return React.cloneElement(info.originNode, {
            ...info.originNode.props,
            className: classNames(
                'peak-load-forecast-calendar-cell-inner',
                { 'week': isInSelectedWeek }
            ),
            children: (
                <div className={dayClass}>{cellDate.get('date')}</div>
            ),
        });
    }

    const headerRender: CalendarProps<Dayjs>['headerRender'] = ({ value, type, onChange: onMonthChange, onTypeChange }) => {
        const year = value.format('YYYY');
        const month = value.format('MMMM');

        const today = new Date();

        return (
            <Row justify='space-between' gutter={8} style={{ padding: 8 }}>
                <Col>
                    <Button
                        type='text'
                        icon={<LeftOutlined />}
                        onClick={() => {
                            const prevMonth = value.clone().month(value.month() - 1);
                            const startMonth = dayjs(startOfMonth(prevMonth.utc().toDate()));
                            onMonthChange(startMonth);
                        }}
                    />
                </Col>
                <Col style={{ lineHeight: '32px' }}>{month + ' ' + year}</Col>
                <Col>
                    <Button
                        type='text'
                        icon={<RightOutlined />}
                        onClick={() => {
                            const nextMonth = value.clone().month(value.month() + 1);
                            if (startOfMonth(nextMonth.toDate()) <= startOfMonth(today)) {
                                const startMonth = dayjs(startOfMonth(nextMonth.utc().toDate()));
                                onMonthChange(startMonth);
                            }
                        }}
                    />
                </Col>
            </Row>
        )
    }

    const onSelect: CalendarProps<Dayjs>['onSelect'] = (date, selectInfo) => {
        const d = date.toDate();

        const key = makeDateKey(d);

        const selectedDate = fromZonedTime(`${key}T00:00:00Z`, plfMarket.timezone);
        console.info(`Calendar cellDate.get('date')=${date.get('date')} onSelect=${date.toDate().toISOString()} selectedDate=${selectedDate.toISOString()}`);
        onChange(selectedDate);
    }

    const disabledDate: CalendarProps<Dayjs>['disabledDate'] = (currentDate: Dayjs) => {
        const today = new Date()
        return currentDate.isAfter(endOfMonth(today));
    }

    return <Calendar
        className='peak-load-forecast-calendar'
        value={dayjs(date)}
        fullscreen={false}
        fullCellRender={cellRender}
        headerRender={headerRender}
        onSelect={onSelect}
    // disabledDate={disabledDate}
    />
}