import Row from 'antd/lib/row';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal/Modal';
import Typography from 'antd/lib/typography';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import { SiteSavedFilterDto } from '../SidebarSavedFilter';
import './sidebarSavedFilterDeleteModal.css';

interface ISidebarSavedFilterDeleteModal {
    filter?: SiteSavedFilterDto;
    onClose: Function;
    onSubmit: Function;
    open: boolean;
    isLoading: boolean;
}

export const SidebarSavedFilterDeleteModal = ({
    filter,
    onClose,
    onSubmit,
    open,
    isLoading,
}: ISidebarSavedFilterDeleteModal) => {
    const handleConfirm = async () => {
        onSubmit(filter);
    };

    const handleClose = () => onClose();

    return (
        <Modal
            open={open}
            title="Confirm Delete"
            destroyOnClose
            closeIcon
            onCancel={handleClose}
            styles={{
                footer: {
                    borderTop: 'none',
                    padding: 24,
                    paddingTop: 0,
                },
            }}
            footer={[
                <Button key="modal-cancel" onClick={handleClose}>
                    Cancel
                </Button>,
                <Button
                    key="delete-confirm"
                    data-cy="delete-confirm"
                    type="primary"
                    onClick={handleConfirm}
                    loading={isLoading}
                >
                    Ok
                </Button>,
            ]}
        >
            <Row align="middle">
                <ExclamationCircleOutlined className="warning-icon" />
                <Typography.Text>
                    Deleting saved filter "<strong>{filter?.filter_name ?? ''}</strong>". Are you sure?
                </Typography.Text>
            </Row>
        </Modal>
    );
};
