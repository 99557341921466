import { TableColumnsType, TableColumnType } from 'antd/lib';
import Table from 'antd/lib/table';
import Typography from 'antd/lib/typography';
import { format } from 'date-fns';
import React from 'react';
import { getReadingsStatsCellColor } from '../../domain/readings-availability-stats/getReadingsStatsCellColor';
import { IGroupedReadingsAvailabilityStat } from '../../domain/readings-availability-stats/interface';
import { useGroupedReadingsAvailabilityStatListQuery } from '../../domain/readings-availability-stats/queries';
import { useReadingsAvailabilityStatsContext } from '../../domain/readings-availability-stats/ReadingsAvailabilityStatsContext';
import { WithEmptyDataTable } from '../table/withEmptyDataTable';

export function ReadingsAvailabilityStatsBySingleCompanyTable() {
    const { startDate, endDate, months, company, period } = useReadingsAvailabilityStatsContext();

    const { data, isLoading } = useGroupedReadingsAvailabilityStatListQuery(
        {
            startDate,
            endDate,
            period,
            companyId: company?.company_id,
            groupedByCompany: true,
        },
        {
            disable: !company,
        }
    );

    const readingsAvailabilityStatsByCompany = data || [];

    const columns: TableColumnsType<IGroupedReadingsAvailabilityStat> = [
        {
            title: 'Company Name',
            dataIndex: 'companyId',
            key: 'companyId',
            fixed: 'left',
            width: 330,
            render: (value: string, record) => (
                <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '300px' }}>
                    {record.companyName}
                </Typography.Text>
            ),
        },

        ...months.map<TableColumnType<IGroupedReadingsAvailabilityStat>>(month => {
            const monthKey = format(month, 'yyyy-MM-01');
            const monthTitle = format(month, 'MMM-yyyy');

            return {
                title: monthTitle,
                key: `stats.${monthKey}`,
                align: 'center',

                width: 130,

                dataIndex: `stats.${monthKey}`,
                render: (_, record) => record.stats[monthKey] || '0',
                onCell: record => ({
                    style: {
                        fontSize: '0.8rem',
                        fontWeight: 'bold',
                        background: getReadingsStatsCellColor(record.stats[monthKey]),
                    },
                }),
            };
        }),
    ];

    return (
        <WithEmptyDataTable condition={isLoading}>
            <Table<IGroupedReadingsAvailabilityStat>
                loading={isLoading}
                columns={columns}
                rowKey={record => record.companyId}
                scroll={{ x: 'max-content' }}
                tableLayout="fixed"
                dataSource={readingsAvailabilityStatsByCompany}
                pagination={{
                    hideOnSinglePage: true,
                }}
            />
        </WithEmptyDataTable>
    );
}
