export enum DataQualityReportPeriod {
    fullday = 'fullday',
    keyhours = 'keyhours',
}

export const DataQualityReportPeriodMap = {
    [DataQualityReportPeriod.fullday]: '24-HOUR',
    [DataQualityReportPeriod.keyhours]: '2PM - 10PM',
};

export interface IFetchGroupedReadingsAvailabilityStats {
    companyId?: number | null;
    groupedByCompany?: boolean;
    startDate: Date;
    endDate: Date;
    period: DataQualityReportPeriod;
    format?: 'json' | 'csv';
}

export interface IGroupedReadingsAvailabilityStat {
    companyId: number;
    companyName: string;
    enrollmentId: string | null;
    nominationKw: number | null;
    updatedAt: string;
    stats: {
        [month: string]: number;
    };
}
