import { UploadFile } from 'antd/lib';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import { isNull, omit } from 'lodash';
import { API } from '../api';
import { readAsBlob } from '../common/readAsBlob';
import { IFetchPageQuery } from '../IFetchQueryOptions';
import { IPagination } from '../IPagination';
import { ISan } from '../site/interface';
import {
    ICreateNyisoResource,
    INyisoResource,
    INyisoSeason,
    IUpdateNyisoResource,
    NyisoResourceUniqueFields,
} from './interface';

export const fetchResources = async (
    { pagination, filter }: IFetchPageQuery,
    signal?: AbortSignal | null
): Promise<IPagination<INyisoResource>> => {
    const limit = pagination?.pageSize || 1000;
    const current = pagination?.current || 1;
    const offset = (current - 1) * limit;

    const path = `/nyiso/resources?${new URLSearchParams({
        ...filter,
        offset: offset.toString(),
        limit: limit.toString(),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot load list of NYISO resources!`);
};

export const createResource = async (resource: ICreateNyisoResource) => {
    const formData = await resourceToFormData(resource);
    const response = await API.fetch('/nyiso/resources', {
        method: 'post',
        body: formData,
    });
    const respBody: any = await response.json();

    if (response.ok) {
        return;
    }

    throw new Error(respBody?.error?.message || `Cannot create NYISO resource!`);
};

export const updateResource = async (resource: IUpdateNyisoResource) => {
    const formData = await resourceToFormData(resource);
    const response = await API.fetch(`/nyiso/resources/${resource.id}`, {
        method: 'put',
        body: formData,
    });
    const respBody: any = await response.json();

    if (response.ok) {
        return;
    }

    throw new Error(respBody?.error?.message || `Cannot update NYISO resource!`);
};

export const deleteResource = async (id: number) => {
    const response = await API.fetch(`/nyiso/resources/${id}`, {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.ok) {
        return;
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot update NYISO resource!`);
};

export const toggleResourceEnabledState = async (params: { enable: boolean; ids?: number[] }) => {
    const response = await API.fetch(`/nyiso/resources/toggle`, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...{ ids: [] }, ...params }),
    });

    if (response.ok) {
        return;
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot toggle NYISO resource enabled state!`);
};

export const getUniqueSansForAutocomplete = async (
    search: string,
    limit: number
): Promise<{ label: string; value: string }[]> => {
    const response = await API.fetch(
        `/sans?${new URLSearchParams({
            search,
            limit: limit.toString(),
            uniqueOnly: 'true',
        })}`,
        {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );

    const respBody = await response.json();

    if (response.ok) {
        const sanList = respBody?.data || [];
        return sanList.map((san: ISan) => ({ value: san.service_account_number, label: san.service_account_number }));
    }

    throw new Error(respBody?.error?.message || `Cannot get list of enrollment IDs!`);
};

export const getSeasonById = async (id: string, signal?: AbortSignal | null): Promise<INyisoSeason | null> => {
    const response = await API.fetch(`/nyiso/seasons/${id}`, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.status === 204) {
        return null;
    }

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot get NYISO season!`);
};

export const uploadPeakHoursSeasonData = async (params: { id: string; file: UploadFile }): Promise<INyisoSeason> => {
    const formData = new FormData();
    const blob = await readAsBlob(params.file as any);
    formData.append('file', blob, params.file.name);

    const response = await API.fetch(`/nyiso/seasons/${params.id}`, {
        method: 'put',
        body: formData,
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot upload peak hours data!`);
};

const resourceToFormData = async (resource: IUpdateNyisoResource | ICreateNyisoResource) => {
    const formData = new FormData();
    const file: any = resource.file;

    if (file) {
        const blob = await readAsBlob(file as any);
        formData.append('file', blob, file.name);
    }

    Object.entries(omit(resource, ['file', 'id'])).forEach(([key, value]) => {
        formData.append(key, !isNull(value) ? value.toString() : '');
    });

    return formData;
};

export const checkForNyisoResourceDuplicateByField = async (
    field: NyisoResourceUniqueFields,
    value: string
): Promise<INyisoResource | null> => {
    const response = await API.fetch(`/nyiso/resources/check-duplicate`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ [field]: value }),
    });

    if (response.status === 204) {
        return null;
    }

    if (response.ok) {
        return await response.json();
    }

    throw new Error(`Cannot check for duplicate NYISO resource by field ${field}!`);
};

export const exportNyisoResourcesAsXlsx = async (seasonId: string) => {
    const response = await API.fetch(`/nyiso/resources/file/${seasonId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/vnd.ms-excel',
        },
    });

    if (response.ok) {
        const responseBlob = await response.blob();
        const filename = `export-nyiso-resources--${format(new Date(), 'MM-dd-yyyy')}.xlsx`;

        saveAs(responseBlob, filename);
    } else {
        const respBody: any = await response.json();
        throw new Error(respBody?.error.message || `Cannot export data!`);
    }
};

export const reprocessResource = async (params: { resourceId: number; seasonId: string }): Promise<void> => {
    const response = await API.fetch(`/nyiso/resources/${params.resourceId}/reprocess`, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ seasonId: params.seasonId }),
    });

    if (response.status === 204) {
        return;
    }

    throw new Error(`Cannot set resource to reprocess!`);
};
