import { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { Collapse, Form } from 'antd';
import SidebarFilter from '../SidebarFilter/SidebarFilter';
import './sidebarPanel.css';
import { ISidebarFilterAndOptionsProps, useSidebar } from '../context/SidebarContext';
import { toUsefulParams } from 'src/pages/toUsefulParams';
import { usePageLocation } from 'src/pages/usePageState';
import { useLocation } from 'react-router-dom';
import { isEmpty, omit } from 'lodash';

const { Panel } = Collapse;

enum SIDEBAR_PANELS {
    FIELDS = 'fields-filter',
    OPTIONS = 'options-filter',
}

export interface ISidebarOptionsPanels {
    rootPage: string;
    filters: ISidebarFilterAndOptionsProps[];
    options: ISidebarFilterAndOptionsProps[];
    activeKey: string[];
    onChange: (keys: string[]) => void;
}

export interface SidebarOptionsPanelsRef {
    /**
     * Resets the forms within the sidebar.
     */
    resetSidebarForms: () => void;
}

const SidebarOptionsPanels = forwardRef<SidebarOptionsPanelsRef, ISidebarOptionsPanels>(
    ({ rootPage, filters, options, activeKey, onChange }, ref) => {
        const location = useLocation();
        const { setPageQuery, queryToState } = usePageLocation();
        const pageState: any = queryToState(location.search);

        const [pageFilters, setPageFilters] = useState<Record<string, any>>(pageState || {});

        const { setHasActiveFilters, selectedFilterId, resetSelectedFilterId } = useSidebar();
        const [form] = Form.useForm();

        useEffect(() => {
            if (selectedFilterId) {
                // When selecting a filter, we need to reset the form and set the page filters using the query params.
                form.resetFields();
                setPageFilters(pageState);
            }
        }, [selectedFilterId]);

        async function onFilterChange(key: string, value: any) {
            const newFilter = { [key]: value };
            const preparedParams = toUsefulParams({
                filter: {
                    ...pageFilters?.filter,
                    ...newFilter,
                },
            });

            setPageFilters(preparedParams);
            let paginationParams = pageState?.pagination || {};
            if (!isEmpty(paginationParams)) {
                paginationParams = omit({ ...paginationParams }, ['current']);
            }

            const params = omit({ ...pageState, ...preparedParams, ...{ pagination: paginationParams } });
            setPageQuery(params);

            setHasActiveFilters(!isEmpty(params?.filter));

            // Any change on the filters should reset the selected filter ID.
            resetSelectedFilterId();
        }

        /**
         * Resets the form and clears the page filters.
         *
         * This function performs the following actions:
         * - Sets the page filters to an empty object.
         * - Resets all fields in the form to their initial values.
         */
        function resetForm() {
            setPageFilters({});
            form.resetFields();
        }
        useEffect(() => {
            if (isEmpty(pageState) && !isEmpty(pageFilters)) {
                resetForm();
            }
        }, [form, pageFilters, pageState]);

        useImperativeHandle(ref, () => ({
            resetSidebarForms: () => {
                resetForm();
            },
        }));

        return (
            <Form.Provider>
                <Form name="filters_form" form={form} preserve={false}>
                    <Collapse
                        activeKey={activeKey}
                        onChange={onChange}
                        bordered={false}
                        expandIconPosition="end"
                        rootClassName="sidebar-accordion-panels"
                    >
                        <Panel className="accordion-panels-filters" header={'Filters'} key={SIDEBAR_PANELS.FIELDS}>
                            {filters
                                ?.filter(Boolean)
                                ?.filter(filter => !isEmpty(filter))
                                ?.map((filter: ISidebarFilterAndOptionsProps) => (
                                    <SidebarFilter
                                        rootKey={rootPage}
                                        key={filter.key}
                                        dataKey={filter.key}
                                        header={filter.title || ''}
                                        subHeader={filter.subHeader}
                                        options={filter.items || []}
                                        filterMultiple={filter.filterMultiple}
                                        defaultValue={pageFilters?.filter?.[filter.key || '']}
                                        onChange={(key, value) => onFilterChange(key, value)}
                                        filterType={filter.filterType}
                                    />
                                ))}
                        </Panel>
                        {options.length > 0 && (
                            <Panel className="accordion-panels-options" header={'Options'} key={SIDEBAR_PANELS.OPTIONS}>
                                {options
                                    ?.filter(Boolean)
                                    ?.filter(option => !isEmpty(option))
                                    ?.map((option: ISidebarFilterAndOptionsProps) => (
                                        <SidebarFilter
                                            rootKey={rootPage}
                                            key={option.key}
                                            dataKey={option.key}
                                            header={option.title || ''}
                                            subHeader={option.subHeader}
                                            options={option.items || []}
                                            filterMultiple={option.filterMultiple}
                                            defaultValue={pageFilters?.filter?.[option.key || '']}
                                            onChange={(key, value) => onFilterChange(key, value)}
                                            filterType={option.filterType}
                                        />
                                    ))}
                            </Panel>
                        )}
                    </Collapse>
                </Form>
            </Form.Provider>
        );
    }
);

export default SidebarOptionsPanels;
