import { useContext, useEffect, useMemo } from 'react';
import Button from 'antd/lib/button';
import Table, { ColumnsType } from 'antd/lib/table';
import { useLocation } from 'react-router-dom';
import { AbilityContext } from 'src/components/ability/can';
import { PageHeader } from 'src/components/pageHeader/pageHeader';
import { SearchInput } from 'src/components/searchInput/searchInput';
import { caisoSibrViewOptions, generateDayPeriod } from 'src/pages/caiso/interface';
import { usePageLocation } from 'src/pages/usePageState';
import { WithEmptyDataTable } from 'src/components/table/withEmptyDataTable';
import { ResourceBidsDto } from 'src/domain/caiso-resource/interface';
import App from 'antd/lib/app';
import { format } from 'date-fns';
import { getColumnFilteredValue } from 'src/components/table/columnFormatHelpers';
import { sortingComparator } from 'src/domain/common/sortingComparator';
import { toUsefulParams } from 'src/pages/toUsefulParams';
import { useScResourceBidsQuery } from 'src/domain/caiso-resource/queries';
import { queryClient, QueryKey } from 'src/query-client';
import { CAISO_SIBR_VIEW } from 'src/pages/interface';

const statusColors: { [key: string]: string } = {
    rejected: 'red',
    invalid: 'red',
    valid: 'green',
    modified: 'blue',
    'conditionally modified': 'green',
    'conditionally valid': 'green',
};

interface SibrOverviewViewProps {
    view: CAISO_SIBR_VIEW;
    handleView: (view: CAISO_SIBR_VIEW) => void;
}

export const SibrOverviewView = ({ view, handleView }: SibrOverviewViewProps) => {
    const { notification } = App.useApp();
    const ability = useContext(AbilityContext);
    const location = useLocation();
    const { setPageQuery, queryToState } = usePageLocation();
    const pageState: any = queryToState(location.search);
    const daysPeriod = generateDayPeriod();
    const resourceNames: string[] = pageState.filter?.resource_name || [];
    const { data, isLoading: loadingResources, error, refetch } = useScResourceBidsQuery(pageState);

    const resourceBids = data || ([] as ResourceBidsDto[]);

    useEffect(() => {
        if (!loadingResources && error) {
            notification.error({
                key: 'caiso-resource-bids-error',
                message: (error as Error)?.message || 'Cannot load bids!',
            });
        }
    }, [loadingResources, error]);

    async function handleSearch(value: string) {
        setPageQuery({ ...pageState, search: value });
        await queryClient.invalidateQueries([QueryKey.SC_RESOURCE_BIDS]);
        refetch();
    }

    const renderStatusDot = (status: string) => (
        <div
            style={{
                backgroundColor: statusColors[status.toLowerCase()],
                width: '0.5em',
                height: '0.5em',
                borderRadius: '50%',
                marginRight: '0.5em',
            }}
        />
    );

    function onTableChange(pagination: any, filter: any, sorter: any) {
        const preparedParams = toUsefulParams({ pagination, filter, sorter });

        setPageQuery({ ...pageState, ...preparedParams });
    }

    const columns: ColumnsType<ResourceBidsDto> = [
        {
            title: 'Resource',
            dataIndex: 'resource_name',
            sorter: (a, b) => sortingComparator(a.resource_name, b.resource_name),
            filters: resourceBids.map(c => ({ text: c.resource_name, value: c.resource_name })),
            filteredValue: getColumnFilteredValue('resource_name', pageState?.filter || {}).filteredValue,
            filterSearch: (input, record) => {
                return !!(record?.text as string)?.toLowerCase().includes(input.toLowerCase());
            },
        },
        ...daysPeriod.map((day, i) => ({
            title: `${format(new Date(day), 'EEE d')}`, // Format as "Mon 20"
            dataIndex: ['days', `${format(new Date(day), 'MM/dd')}`],
            key: `${day}`,
            render: (_: any, el: any) => {
                const status = el[`da_days_${i + 1}`]?.status;
                return status ? (
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        {renderStatusDot(status)}
                        {status?.charAt(0).toUpperCase() + status?.slice(1)}
                    </span>
                ) : null;
            },
            sorter: (a: any, b: any) => sortingComparator(a[`da_days_${i + 1}`]?.status, b[`da_days_${i + 1}`]?.status),
        })),
    ];

    const filteredCaisoResources = useMemo(() => {
        return !resourceNames.length
            ? resourceBids
            : resourceBids.filter(resource => resourceNames?.includes(resource.resource_name));
    }, [resourceBids, resourceNames]);

    return (
        <>
            <PageHeader
                pageTitle={`CAISO SC (${resourceBids.length})`}
                actions={[
                    <SearchInput
                        key="search-input-sibr-overview-view"
                        onSearch={handleSearch}
                        defaultValue={pageState.search}
                    />,
                ]}
            />
            <WithEmptyDataTable condition={loadingResources}>
                <Table
                    size="small"
                    rowKey="id"
                    sticky
                    scroll={{
                        x: 1300,
                    }}
                    onChange={onTableChange}
                    columns={columns}
                    dataSource={filteredCaisoResources}
                    pagination={false}
                    loading={loadingResources}
                />
            </WithEmptyDataTable>
        </>
    );
};
