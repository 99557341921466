import { memo } from 'react';
import { parseISO } from 'date-fns';
import Popover from 'antd/lib/popover';
import { getTimezoneOffset } from 'date-fns-tz';
import './datetimePopover.css';
import {
    DEFAULT_DATE_FORMAT,
    getComputerFormattedTime,
    getTimezoneFormattedTime,
    getUtcFormattedTime,
} from 'src/domain/date/date';

interface IDatetimePopover {
    displayUtc?: boolean;
    timestamp: number;
    dateFormat?: string;
    timeZone?: string;
}

const MS_IN_HOUR = 1000 * 3600;

function getUtcDate(timestamp: number): Date {
    return parseISO(new Date(timestamp).toISOString());
}

function getTimezoneOffsetInHours(utcDate: Date, timeZone?: string): number {
    if (!timeZone) return 0;

    return getTimezoneOffset(timeZone, utcDate) / MS_IN_HOUR;
}

export function DatetimePopover({
    displayUtc,
    timestamp,
    dateFormat = DEFAULT_DATE_FORMAT,
    timeZone,
}: IDatetimePopover) {
    const utcDate = getUtcDate(timestamp);
    const utcFormattedTime = getUtcFormattedTime(utcDate, dateFormat);
    const timezoneFormattedTime = getTimezoneFormattedTime(utcDate, timeZone, dateFormat);
    const computerFormattedTime = getComputerFormattedTime(utcDate, dateFormat);
    const timezoneOffsetInHours = getTimezoneOffsetInHours(utcDate, timeZone);

    let label: string = computerFormattedTime;
    let content: JSX.Element | null = (
        <div className="datetime-popover">
            <p>UTC: {utcFormattedTime}</p>
            <p>Computer: {computerFormattedTime}</p>
            {timeZone && <p>Timezone: {timezoneFormattedTime}</p>}
        </div>
    );

    if (displayUtc) {
        label = utcFormattedTime + ' UTC';
        content = (
            <div className="datetime-popover">
                {timeZone && (
                    <p>
                        Local time: {timezoneFormattedTime} ({timezoneOffsetInHours}h)
                    </p>
                )}
            </div>
        );
    }

    return (
        <Popover content={content}>
            <div data-cy="date-time">{label}</div>
        </Popover>
    );
}

export const MemoizedDatetimePopover = memo(DatetimePopover);
