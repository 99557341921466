import { ColumnFilterItem } from 'antd/es/table/interface';
import React, { createContext, useContext, useState, useCallback, useEffect, useMemo } from 'react';
import { API } from 'src/domain/api';
import { DEFAULT_SIDEBAR_WIDTH, LOCAL_STORAGE_PREFIX } from 'src/domain/commonConst';
import { OptionsDto, SiteSavedFilterDto } from '../SidebarSavedFilter/SidebarSavedFilter';

interface ISidebarProvider {
    children: React.ReactNode;
}

export interface ISidebarConfig {
    width?: number;
    collapsed?: boolean;
    defaultSidebarAccordionPanel?: string;
    defaultPanelFilter?: Record<string, string>;
    activePanels?: string[];
}

export enum SidebarFilterType {
    CHECKBOX = 'checkbox',
    TOGGLE = 'toggle',
}
export interface ISidebarFilterAndOptionsProps {
    title?: string;
    subHeader?: string;
    items?: ColumnFilterItem[];
    key?: string;
    filteredValue?: any | null;
    filterMultiple?: boolean;
    filterType?: SidebarFilterType;
}

export interface ISidebarFilterAndOptions {
    rootPage?: string;
    props?: ISidebarFilterAndOptionsProps[];
}
export interface IFilterOption {
    title: string;
    key: string;
    value: string;
    textValue: string;
}

enum SidebarFilterItem {
    control_provider_id = 'control_provider_id',
    utility_customer_id = 'utility_customer_id',
    program_id = 'program_id',
    site_utility = 'site_utility',
    lmp_market = 'lmp_market',
}

enum SidebarOptionItem {
    exist_salesforce_meter_enrollments = 'exist_salesforce_meter_enrollments',
    exist_salesforce_meters = 'exist_salesforce_meters',
    interval_data = 'interval_data',
    site_is_closed = 'site_is_closed',
}

type SidebarAllowedKeysItems = keyof typeof SidebarFilterItem & keyof typeof SidebarOptionItem;

interface ISidebarContext {
    // Temporary value (not stored)
    resultAmountValue: number;
    setResultAmountValue: (value: number) => void;

    // Persistent config object
    config: ISidebarConfig;
    setConfig: (config: Record<string, any>) => void;
    updateConfig: (partialConfig: Record<string, any>) => void;

    // set filter and options into storage
    setFilterItem: <T>(key: string, value: T) => void;
    getFilterItem: (key: string) => Record<string, any> | null;
    removeFilterItem: (key: string) => void;
    getAllFilterItems: () => Record<string, any>;

    // filters and options values
    filters: ISidebarFilterAndOptions;
    setFilters: (items: ISidebarFilterAndOptions) => void;
    options: ISidebarFilterAndOptions;
    setOptions: (items: ISidebarFilterAndOptions) => void;

    // Utility functions
    resetAll: () => void;

    fetchAllSavedFilters: () => void;
    savedFilters: SiteSavedFilterDto[];
    getSelectedFiltersToDisplay: (currentFilters: Record<string, string | string[]> | undefined) => IFilterOption[];
    getSelectedFilterFromItemToDisplay: (item: SiteSavedFilterDto) => IFilterOption[];

    hasActiveFilters: boolean;
    setHasActiveFilters: (value: boolean) => void;

    toggleDashboardDisplay: (filterId: number) => Promise<void>;

    selectedFilterId: number | null;
    setSelectedFilterId: (id: number | null) => void;
    resetSelectedFilterId: () => void;
}

const SidebarContext = createContext<ISidebarContext | undefined>(undefined);

// Constants for storage keys
const CONFIG_STORAGE_KEY = `${LOCAL_STORAGE_PREFIX}.sidebar_config`;
const FILTERS_STORAGE_PREFIX = `${LOCAL_STORAGE_PREFIX}.filters.`;

export const SidebarProvider: React.FC<ISidebarProvider> = ({ children }) => {
    // State for temporary value (not persisted)
    const [resultAmountValue, setResultAmountValue] = useState<number>(0);
    const [hasActiveFilters, setHasActiveFilters] = useState<boolean>(false);
    const [options, setOptions] = useState<ISidebarFilterAndOptions>({});
    const [filters, setFilters] = useState<ISidebarFilterAndOptions>({});
    const [savedFilters, setSavedFilters] = useState<SiteSavedFilterDto[]>([]);
    const [selectedFilterId, setSelectedFilterId] = useState<number | null>(null);

    const defaultConfig = useMemo(() => {
        return {
            width: DEFAULT_SIDEBAR_WIDTH,
            collapsed: false,
            defaultSidebarAccordionPanel: '',
        };
    }, []);

    const fetchAllSavedFilters = () => {
        API.fetch('/filters')
            .then(response => response.json())
            .then(data => {
                setSavedFilters(data);
            })
            .catch(err => {
                console.log(err);
            });
    };

    useEffect(() => {
        fetchAllSavedFilters();
    }, []);

    // State for persistent config
    const [config, setConfigState] = useState<Record<string, any>>(() => {
        try {
            const storedConfig = localStorage.getItem(CONFIG_STORAGE_KEY);
            return storedConfig
                ? {
                    ...defaultConfig,
                    ...JSON.parse(storedConfig),
                }
                : defaultConfig;
        } catch (error) {
            console.error('Error loading config from local storage:', error);
            return {};
        }
    });

    // Update localStorage when config changes
    useEffect(() => {
        try {
            localStorage.setItem(CONFIG_STORAGE_KEY, JSON.stringify(config));
        } catch (error) {
            console.error('Error saving config to local storage:', error);
        }
    }, [config]);

    // Update config partially
    const updateConfig = useCallback((partialConfig: Record<string, any>) => {
        setConfigState(prevConfig => ({
            ...prevConfig,
            ...partialConfig,
        }));
    }, []);

    // Dynamic storage operations
    const setFilterItem = useCallback(<T,>(key: string, value: T) => {
        try {
            const storageKey = `${FILTERS_STORAGE_PREFIX}${key}`;
            localStorage.setItem(storageKey, JSON.stringify(value));
        } catch (error) {
            console.error(`Error saving dynamic item ${key} to localStorage:`, error);
        }
    }, []);

    const getFilterItem = useCallback(<T,>(key: string): T | null => {
        try {
            const storageKey = `${FILTERS_STORAGE_PREFIX}${key}`;
            // const item = localStorage.getItem(storageKey);
            //  return item ? JSON.parse(item) : null;
            return null;
        } catch (error) {
            console.error(`Error loading filter item ${key} from local storage:`, error);
            return null;
        }
    }, []);

    const removeFilterItem = useCallback((key: string) => {
        try {
            const storageKey = `${FILTERS_STORAGE_PREFIX}${key}`;
            localStorage.removeItem(storageKey);
        } catch (error) {
            console.error(`Error removing filter item ${key} from local storage:`, error);
        }
    }, []);

    const getAllFilterItems = useCallback(() => {
        const items: Record<string, any> = {};
        try {
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                if (key?.startsWith(FILTERS_STORAGE_PREFIX)) {
                    const value = localStorage.getItem(key);
                    if (value) {
                        const actualKey = key.replace(FILTERS_STORAGE_PREFIX, '');
                        items[actualKey] = JSON.parse(value);
                    }
                }
            }
        } catch (error) {
            console.error('Error loading all filters from local storage:', error);
        }
        return items;
    }, []);

    const resetAll = useCallback(() => {
        try {
            // Clear only our app's storage items
            for (let i = localStorage.length - 1; i >= 0; i--) {
                const key = localStorage.key(i);
                if (key === CONFIG_STORAGE_KEY || key?.startsWith(FILTERS_STORAGE_PREFIX)) {
                    localStorage.removeItem(key);
                }
            }
            setConfigState(defaultConfig);
            setHasActiveFilters(false);
        } catch (error) {
            console.error('Error clearing storage:', error);
        }
    }, [defaultConfig]);

    const getSelectedFiltersToDisplay = (currentFilters: Record<string, string | string[]> | undefined) => {
        const newSelectedFilters: IFilterOption[] = [];

        const allFilters: ISidebarFilterAndOptionsProps[] = [];
        const allOptions: ISidebarFilterAndOptionsProps[] = [];

        for (let item of filters.props ?? []) {
            allFilters.push(item);
        }

        for (let item of options.props ?? []) {
            allOptions.push(item);
        }

        if (currentFilters) {
            Object.keys(currentFilters).forEach(key => {
                const valueFilter = allFilters.filter(f => f.key === key);
                if (valueFilter && valueFilter.length > 0) {
                    const isMultipleSelection = typeof currentFilters[key] === 'object';
                    let option = undefined;
                    if (!isMultipleSelection) {
                        option = valueFilter[0].items?.filter(v => v.value == currentFilters[key])[0] as any;
                        if (option) {
                            newSelectedFilters.push({
                                key: key,
                                title: valueFilter[0].title!,
                                value: currentFilters[key].toString(),
                                textValue: key === 'program_id' ? option.fullText : (option.text as string),
                            });
                        }
                    } else {
                        option = valueFilter[0].items?.filter(v => currentFilters[key].includes(v.value.toString()));
                        if (option) {
                            newSelectedFilters.push({
                                key: key,
                                title: valueFilter[0].title!,
                                value: currentFilters[key].toString(),
                                textValue:
                                    key === 'program_id'
                                        ? option.map((o: any) => o.fullText).join(' , ')
                                        : option.map(o => o.text).join(' , '),
                            });
                        }
                    }
                }

                const valueOption = allOptions.filter(o => o.key === key);
                if (valueOption && valueOption.length > 0) {
                    const option = valueOption[0].items?.filter(v => v.value == currentFilters[key])[0];
                    if (option) {
                        newSelectedFilters.push({
                            key: key,
                            title: valueOption[0].title!,
                            value: currentFilters[key].toString(),
                            textValue: option.text as string,
                        });
                    }
                }
            });
        }
        return newSelectedFilters;
    };

    const _formatSelectionValues = (
        items: OptionsDto,
        currentFilters: ISidebarFilterAndOptionsProps[],
        currentOptions: ISidebarFilterAndOptionsProps[]
    ) => {
        const newSelectedFilters: IFilterOption[] = [];

        const itemsKeys = Object.keys(items || {});

        for (let index = 0; index < itemsKeys.length; index++) {
            const key = itemsKeys[index] as SidebarAllowedKeysItems;
            const isOption = SidebarOptionItem[key] || null;

            const valueOption = (isOption ? currentOptions : currentFilters)?.filter(val => val.key === key);

            const option = valueOption[0].items?.filter(
                v => v.value === items[key] || `${items[key]}`?.split(',').includes(v.value.toString())
            )[0];

            if (option) {
                newSelectedFilters.push({
                    key,
                    title: valueOption[0]?.title!,
                    value: `${items[key]}`,
                    textValue: option.text as string,
                });
            }
        }

        return newSelectedFilters;
    };

    const getSelectedFilterFromItemToDisplay = (item: SiteSavedFilterDto) => {
        const allFilters: ISidebarFilterAndOptionsProps[] = [];
        const allOptions: ISidebarFilterAndOptionsProps[] = [];

        for (let item of filters.props ?? []) {
            allFilters.push(item);
        }

        for (let item of options.props ?? []) {
            allOptions.push(item);
        }

        const newSelectedFilters = _formatSelectionValues(item.filter_values, allFilters, allOptions);

        return newSelectedFilters;
    };

    const toggleDashboardDisplay = async (filterId: number) => {
        try {
            const response = await API.fetch(`/filters/${filterId}/toggle-dashboard`, { method: 'PUT' });
            if (response.ok) {
                fetchAllSavedFilters();
            } else {
                console.error('Failed to toggle dashboard display.');
            }
        } catch (err) {
            console.error('Failed to toggle dashboard display.', err);
        }
    };

    const resetSelectedFilterId = () => {
        setSelectedFilterId(null);
    };

    const value = {
        resultAmountValue,
        setResultAmountValue,
        hasActiveFilters,
        setHasActiveFilters,
        config,
        setConfig: setConfigState,
        updateConfig,
        setFilterItem,
        getFilterItem,
        removeFilterItem,
        getAllFilterItems,
        resetAll,
        options,
        filters,
        setOptions,
        setFilters,
        savedFilters,
        fetchAllSavedFilters,
        getSelectedFiltersToDisplay,
        getSelectedFilterFromItemToDisplay,
        toggleDashboardDisplay,
        selectedFilterId,
        setSelectedFilterId,
        resetSelectedFilterId,
    };

    return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>;
};

// Custom hook for using the sidebar context
export const useSidebar = () => {
    const context = useContext(SidebarContext);
    if (context === undefined) {
        throw new Error('useSidebar must be used within a SidebarProvider');
    }
    return context;
};
