import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { fetchCaisoSyncs, syncNow } from '.';
import { QueryKey } from 'src/query-client';
import { IFetchPageQuery } from 'src/domain/IFetchQueryOptions';
import { CaisoSyncOrigin } from './interface';
import { App } from 'antd';

export const useCaisoSyncListQuery = (origin: CaisoSyncOrigin, query: IFetchPageQuery, options = {}) => {
    return useQuery({
        queryKey: [QueryKey.DRRS_SYNCS, query],
        queryFn: () => fetchCaisoSyncs(origin, query),
        ...options,
    });
};

export const useCaisoSyncNowMutation = () => {
    const { notification } = App.useApp();
    const queryClient = useQueryClient();

    return useMutation(syncNow, {
      onSuccess: async () => {
            await queryClient.invalidateQueries([QueryKey.DRRS_SYNCS]);
            notification.success({ key: 'sync-now-success', message: 'Request created successfully' });
        },
        onError: (error: Error) => {
            notification.error({ key: 'sync-now-error', message: error.message || 'Cannot create request' });
        },
    });
};
