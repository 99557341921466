import { RangePickerProps } from 'antd/lib/date-picker';
import { startOfYear, endOfYear } from 'date-fns';
import { IDateRange } from '../../../pages/interface';
import { eachYearOfInterval, format, subDays, startOfMonth, endOfMonth } from 'date-fns';
import { TimeRangePickerProps, DatePicker } from 'antd/lib';
import dayjs from 'dayjs';
import React from 'react';

export enum DatePeriod {
    ANNUALLY = 'annually',
    QUARTERLY = 'quarterly',
    MONTHLY = 'monthly',
}

export const DATE_FORMAT = {
    [DatePeriod.ANNUALLY]: 'yyyy',
    [DatePeriod.QUARTERLY]: 'QQQ yyyy',
    [DatePeriod.MONTHLY]: 'MMMM yyyy',
};

export const DEFAULT_START_OF_PERIOD = new Date('2021-01-01');
export const DEFAULT_DATE_RANGE = {
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
};

export const getYearsPresets = () => {
    const dateYears = eachYearOfInterval({ start: DEFAULT_START_OF_PERIOD, end: new Date() }).reverse();
    return dateYears.map(year => ({
        label: `${format(year, DATE_FORMAT[DatePeriod.ANNUALLY])} Year`,
        value: [dayjs(startOfYear(year)), dayjs(endOfYear(year))],
    }));
};

export const getPresets = () => {
    const yearsPresets = getYearsPresets();
    return [
        { label: 'Last 7 Days', value: [dayjs(subDays(new Date(), 7)), dayjs(new Date())] },
        { label: 'Last 14 Days', value: [dayjs(subDays(new Date(), 14)), dayjs(new Date())] },
        { label: 'Last 30 Days', value: [dayjs(subDays(new Date(), 30)), dayjs(new Date())] },
        ...yearsPresets,
    ] as TimeRangePickerProps['presets'];
};

interface IDateSelectorWithPeriod {
    disabled?: boolean;
    onChange: any;
    selectedPeriod: IDateRange;
    memoized?: boolean;
    disabledDate?: RangePickerProps['disabledDate'];
    displayDateFormat?: string;
}

export const DatePeriodSelectorWithPresets = ({
    selectedPeriod,
    onChange,
    memoized = false,
    disabled = false,
    disabledDate = () => false,
    displayDateFormat = 'DD/MMM/YYYY',
}: IDateSelectorWithPeriod) => {
    const MemoizedDatePicker = React.memo(DatePicker.RangePicker);

    const validateStartDate = (date: any) => dayjs(date).isValid() ? dayjs(date) : dayjs(DEFAULT_DATE_RANGE.start);
    const validateEndDate = (date: any) => dayjs(date).isValid() ? dayjs(date) : dayjs(DEFAULT_DATE_RANGE.end);

    const start = validateStartDate(selectedPeriod.start);
    const end = validateEndDate(selectedPeriod.end);

    return memoized ? (
        <MemoizedDatePicker
            size="large"
            key="date-period-selector"
            data-cy="date-period-selector"
            format={displayDateFormat}
            presets={getPresets()}
            allowClear={false}
            value={[start, end]}
            disabled={disabled}
            onChange={onChange}
            disabledDate={disabledDate}
        />
    ) : (
        <DatePicker.RangePicker
            size="large"
            key="date-period-selector"
            data-cy="date-period-selector"
            format={displayDateFormat}
            presets={getPresets()}
            allowClear={false}
            value={[start, end]}
            disabled={disabled}
            onChange={onChange}
            disabledDate={disabledDate}
        />
    );
};