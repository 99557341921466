import { format } from 'date-fns-tz';
import isNil from 'lodash/isNil';
import orderBy from 'lodash/orderBy';
import { ICloseSiteFormData } from '../../components/site/CloseSiteModal';
import { IMergeSitesReqDto } from '../../components/site/merge-sites/interface';
import { API } from '../api';
import { exportCSVData } from '../common/exportCSVData';
import { DATE_FORMAT_CSV } from '../commonConst';
import { IFetchPageQuery } from '../IFetchQueryOptions';
import { IPagination } from '../IPagination';
import {
    IBatchUpdatedFields,
    ICreateSite,
    IEnrollSitesBatchToPrograms,
    IEnrollSiteToPrograms,
    IFetchLocationsQuery,
    IFetchSiteLabelsQuery,
    IFetchSiteLabelsResult,
    IFetchSitePageQuery,
    IMeter,
    ISan,
    ISanFormItem,
    ISanInfo,
    ISite,
    ISiteAddressCheckRequest,
    ISiteEnrollment,
    ISiteLocation,
    ISiteStoreNumberCheckRequest,
    ISiteStoreNumberCheckResponse,
    IUpdateSite,
} from './interface';

export const fetchLocations = async (
    { companyId = null }: IFetchLocationsQuery,
    signal?: AbortSignal | null
): Promise<ISiteLocation[]> => {
    const path = `/sites/locations?${new URLSearchParams({
        ...(companyId && { companyId: `${companyId}` }),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot load list of locations!`);
};

export const fetchSiteLabels = async (
    { companyId = null }: IFetchSiteLabelsQuery,
    signal?: AbortSignal | null
): Promise<IFetchSiteLabelsResult> => {
    const path = `/sites/labels?${new URLSearchParams({
        ...(companyId && { companyId: `${companyId}` }),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot load list of site labels!`);
};

export const fetchSites = async (
    { pagination, sorter, filter, search, include, company_id = null, fields, hasLabel }: IFetchSitePageQuery,
    signal?: AbortSignal | null
): Promise<IPagination<ISite>> => {
    const limit = pagination?.pageSize && pagination?.pageSize > 0 ? pagination.pageSize : 10;
    const offset = pagination?.current && pagination?.current > 0 ? (pagination.current - 1) * limit : 0;

    const path = `/sites?${new URLSearchParams({
        offset: offset.toString(),
        limit: limit.toString(),
        ...prepareSitesOrder(sorter),
        ...prepareSitesFilter(filter),
        ...(search && { search }),
        ...(include && { include }),
        ...(company_id && { company_id }),
        ...(fields && { fields }),
        ...(hasLabel && { hasLabel }),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot load list of sites!`);
};

export const fetchSiteUtilities = async (signal?: AbortSignal | null): Promise<string[]> => {
    const path = `/sites/utilities`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody.map((item: { value: string }) => item.value);
    }

    throw new Error(respBody?.error?.message || `Cannot get site utilities!`);
};

function prepareSitesOrder(sorter: IFetchSitePageQuery['sorter']) {
    if (!sorter || !sorter.field || !sorter.order) {
        return {};
    }

    if (sorter.field === 'control_provider_id') {
        sorter.field = 'control_provider_name';
    }

    if (sorter.field === 'utility_customer_id') {
        sorter.field = 'utility_customer_name';
    }

    return { sortField: sorter.field, sortOrder: sorter.order };
}

function prepareSitesFilter(siteFilter: IFetchSitePageQuery['filter']): IFetchSitePageQuery['filter'] {
    const filter = { ...siteFilter };

    if (filter?.control_provider_id) {
        filter.controlProviderId = filter.control_provider_id;
        delete filter.control_provider_id;
    }

    if (filter?.utility_customer_id) {
        filter.utilityCustomerId = filter.utility_customer_id;
        delete filter.utility_customer_id;
    }

    if (filter && isNil(filter.controlProviderId)) {
        delete filter.controlProviderId;
    }

    if (filter && isNil(filter.program_id)) {
        delete filter.program_id;
    }

    if (filter && 'lmp_market' in filter) {
        // Because of the way the API is set up, we need to pass 'null' as the market to return without market
        // value must be unique and null at same time, as it's used as key in selector
        if (filter.lmp_market.includes('null')) {
            filter.lmp_market = 'null';
        }

        // Because of the way the API is set up, we need to pass 'undefined' as the market to return 'all'
        // value must be unique and null at same time, as it's used as key in selector
        if (filter.lmp_market.includes('undefined')) {
            filter.lmp_market = undefined;
        }

        filter.market = filter.lmp_market; // rename field because API expects market instead of lmp_market

        delete filter.lmp_market;
    }

    if (filter && 'load_zone' in filter) {
        // Because of the way the API is set up, we need to pass 'null' as the load_zone to return without load_zone
        // value must be unique and null at same time, as it's used as key in selector
        if (filter.load_zone.includes('null')) {
            filter.load_zone = 'null';
        }

        // Because of the way the API is set up, we need to pass 'undefined' as the load_zone to return 'all'
        // value must be unique and null at same time, as it's used as key in selector
        if (filter.load_zone.includes('undefined')) {
            filter.load_zone = undefined;
        }
    }

    if (typeof filter.isClosed === 'undefined') {
        filter.isClosed = false;
    }

    return filter;
}

function prepareMeterOrEnrollmentsFilter(siteFilter: IFetchSitePageQuery['filter']): IFetchSitePageQuery['filter'] {
    const filter: IFetchSitePageQuery['filter'] = {};

    if (!isNil(siteFilter?.isClosed)) {
        filter.isClosed = siteFilter?.isClosed.toString();
    }

    if (!isNil(siteFilter?.interval_data)) {
        filter.intervalData = siteFilter?.interval_data.toString();
    }

    if (!isNil(siteFilter?.exist_salesforce_meters)) {
        filter.existSalesforceMeters = siteFilter?.exist_salesforce_meters.toString();
    }

    if (!isNil(siteFilter?.exist_salesforce_meter_enrollments)) {
        filter.existSalesforceMeterEnrollments = siteFilter?.exist_salesforce_meter_enrollments.toString();
    }

    if (siteFilter?.control_provider_id) {
        filter.controlProviderId = siteFilter.control_provider_id;
    }

    if (siteFilter?.program_id) {
        filter.programId = siteFilter.program_id;
    }

    if (siteFilter && 'lmp_market' in siteFilter) {
        // Because of the way the API is set up, we need to pass 'null' as the market to return without market
        // value must be unique and null at same time, as it's used as key in selector
        if (siteFilter.lmp_market.includes('null')) {
            siteFilter.lmp_market = 'null';
        }

        // Because of the way the API is set up, we need to pass 'undefined' as the market to return 'all'
        // value must be unique and null at same time, as it's used as key in selector
        if (siteFilter.lmp_market.includes('undefined')) {
            siteFilter.lmp_market = undefined;
        }

        filter.market = siteFilter.lmp_market; // rename field because API expects market instead of lmp_market
    }

    return filter;
}

export const fetchSite = async (siteId: number, include?: string): Promise<ISite> => {
    const path = `/sites/${siteId}?${new URLSearchParams({
        ...(include && { include }),
    })}`;
    const response = await API.fetch(path, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.ok) {
        return await response.json();
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot fetch site!`);
};

export const deleteSite = async (site: ISite) => {
    const response = await API.fetch(`/sites/${site.site_id}`, {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.ok) {
        return;
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot delete site!`);
};

export const createSite = async (site: ICreateSite) => {
    const response = await API.fetch(`/sites`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            company_id: site.company_id,
            control_provider_id: site.control_provider_id || null,
            utility_customer_id: site.utility_customer_id || null,
            program_ids: site.program_ids || undefined,
            site_name: site.site_name,
            site_address: site.site_address,
            site_city: site.site_city,
            site_state: site.site_state,
            site_zip: site.site_zip ? `${site.site_zip}` : null,
            site_country: site.site_country,
            site_county: site.site_county || null,
            site_lat: site.site_lat,
            site_long: site.site_long,
            site_timezone: site.site_timezone,
            google_place_id: site.google_place_id,
            site_store_number: site.site_store_number,
            site_utility: site.site_utility,
            site_slap: site.site_slap,
            event_offset: site.event_offset,
            site_estimated_kw: site.site_estimated_kw || null,
            event_max_duration: site.event_max_duration || null,
            site_resource_id: site.site_resource_id,
            partner_id: site.partner_id?.trim() || null,
            site_highlight: site.site_highlight,
            create_customer_ven: site.create_customer_ven || false,
            site_network: site.site_network || null,
            email_only_integration: site.email_only_integration || false,
            site_load_zone: site.site_load_zone?.toUpperCase() || null,
            lmp_market: site.lmp_market?.toLowerCase() || null,
            site_label: site.site_label || [],
            meter_ids: site.meter_ids || [],
            sans: site.sans || [],
        }),
    });
    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot save site!`);
};

export const updateSite = async (site: IUpdateSite): Promise<ISite> => {
    const response = await API.fetch(`/sites/${site.site_id}`, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            company_id: site.company_id,
            site_name: site.site_name,

            // site address details
            site_address: site.site_address,
            site_city: site.site_city,
            site_state: site.site_state,
            site_zip: `${site.site_zip}`,
            site_country: site.site_country,
            site_county: site.site_county || null,
            site_lat: site.site_lat,
            site_long: site.site_long,
            site_timezone: site.site_timezone,
            google_place_id: site.google_place_id,
            site_store_number: site.site_store_number,
            site_utility: site.site_utility || null,
            site_slap: site.site_slap,
            control_provider_id: site.control_provider_id || null,
            utility_customer_id: site.utility_customer_id || null,
            event_offset: site.event_offset,
            site_estimated_kw: site.site_estimated_kw || null,
            event_max_duration: site.event_max_duration || null,
            site_resource_id: site.site_resource_id || null,
            partner_id: site.partner_id?.trim() || null,
            site_highlight: site.site_highlight,
            site_network: site.site_network || null,
            email_only_integration: site.email_only_integration,
            site_load_zone: site.site_load_zone?.toUpperCase() || null,
            lmp_market: site.lmp_market?.toLowerCase() || null,

            site_label: site.site_label || [],
            meter_ids: site.meter_ids || [],

            sans: site?.sans,
        }),
    });
    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot save site!`);
};

export const exportSites = async ({
    filter,
    search,
    company_id = null,
    include = 'company,control_provider,utility_customer,program,san',
    fileColumnsPreset,
    isClosed,
}: IFetchPageQuery) => {
    const query = new URLSearchParams({
        format: 'csv',
        include,
        ...(!isNil(isClosed) && { isClosed: isClosed.toString() }), // Include isClosed in the query string
        ...prepareSitesFilter(filter),
        ...(search && { search }),
        ...(company_id && { company_id }),
        ...(fileColumnsPreset && { fileColumnsPreset }),
    });

    const response = await API.fetch(`/sites?${query}`, {
        headers: {
            'Content-Type': 'text/csv',
        },
    });

    const respBody: string = await response.text();

    if (response.ok) {
        const isDetailedFile = fileColumnsPreset === 'detailed';
        const fileName = `sites${isDetailedFile ? '-detailed' : ''}--${format(new Date(), DATE_FORMAT_CSV)}.csv`;
        exportCSVData(respBody, fileName);
    } else {
        const respBody: any = await response.json();
        throw new Error(respBody?.error?.message || `Cannot export data!`);
    }
};

export const enrollSiteToPrograms = async (site: ISite, enrollments: IEnrollSiteToPrograms) => {
    const response = await API.fetch(`/sites/${site.site_id}/programs`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(enrollments),
    });

    if (response.ok) {
        return;
    }
    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot save changes!`);
};

export const getSiteSanInfoSources = async (siteId: number): Promise<ISanInfo[]> => {
    const response = await API.fetch(`/sites/${siteId}/san-infos`, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.ok) {
        const respBody: any = await response.json();
        return respBody;
    }

    const resp: any = response;
    throw new Error(resp?.error?.message || `Cannot get san details!`);
};

export const enrollSites = async (sitesId: number[], enrollment: ISiteEnrollment): Promise<void> => {
    const response = await API.fetch(`/sites/enrollment`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            sitesId,
            ...enrollment,
        }),
    });

    if (response.ok) {
        return;
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot enroll sites!`);
};

export const openSite = async (siteId: number): Promise<ISite> => {
    const response = await API.fetch(`/sites/${siteId}/open`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot open site!`);
};

export const closeSite = async (siteId: number, data: ICloseSiteFormData): Promise<ISite> => {
    const response = await API.fetch(`/sites/${siteId}/close`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot close site!`);
};

export const updateBatchSites = async (sitesId: number[], updatedFields: IBatchUpdatedFields) => {
    const response = await API.fetch(`/sites/batch`, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sitesId, update: updatedFields }),
    });

    if (response.ok) {
        return;
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot update sites batch!`);
};

export const attachProgramsToSitesBatch = async (enrollmentInfo: IEnrollSitesBatchToPrograms) => {
    const response = await API.fetch(`/sites/batch/enrollment`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(enrollmentInfo),
    });

    if (response.ok) {
        return;
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot attach programs to sites batch!`);
};

export const exportMeters = async ({ company_id = null, filter, search }: IFetchPageQuery) => {
    const query = new URLSearchParams({
        ...prepareMeterOrEnrollmentsFilter(filter),
        ...(search && { search }),
        ...(company_id && { companyId: company_id }),
        format: 'csv',
        include: 'site,account',
    });
    const response = await API.fetch(`/sites/meters?${query}`, {
        headers: {
            'Content-Type': 'text/csv',
        },
    });

    const respBody: string = await response.text();

    if (response.ok) {
        const fileName = `meter--${format(new Date(), DATE_FORMAT_CSV)}.csv`;
        exportCSVData(respBody, fileName);
    } else {
        const respBody: any = await response.json();
        throw new Error(respBody?.error?.message || `Cannot export data!`);
    }
};

export const exportMeterEnrollments = async ({ company_id = null, filter, search }: IFetchPageQuery) => {
    const query = new URLSearchParams({
        ...prepareMeterOrEnrollmentsFilter(filter),
        ...(search && { search }),
        ...(company_id && { companyId: company_id }),
        format: 'csv',
        include: 'meter,site,product',
    });
    const response = await API.fetch(`/sites/meter-enrollments?${query}`, {
        headers: {
            'Content-Type': 'text/csv',
        },
    });

    const respBody: string = await response.text();

    if (response.ok) {
        const fileName = `meter-enrollment--${format(new Date(), DATE_FORMAT_CSV)}.csv`;
        exportCSVData(respBody, fileName);
    } else {
        const respBody: any = await response.json();
        throw new Error(respBody?.error?.message || `Cannot export data!`);
    }
};

export const checkIfCanUseSan = async (san: string, siteId?: number | null): Promise<boolean> => {
    const response = await API.fetch(
        `/sans/${san}/check?${new URLSearchParams({
            ...(siteId && { siteId: siteId.toString() }),
        })}`,
        {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot check if SAN can be used!`);
};

export const updateSiteSans = async (siteId: number, sans: ISanFormItem[]): Promise<ISan[]> => {
    const response = await API.fetch(`/sites/${siteId}/sans`, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sans }),
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot update site SANs!`);
};

export const checkForAddressDuplicate = async (data: ISiteAddressCheckRequest): Promise<ISite[]> => {
    const response = await API.fetch('/sites/check-duplicated-address', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot check address for duplicates!`);
};

export const checkForStoreNumberDuplicates = async (
    data: ISiteStoreNumberCheckRequest[]
): Promise<ISiteStoreNumberCheckResponse[]> => {
    const response = await API.fetch('/sites/check-duplicated-store-numbers', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data }),
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot validate site store number!`);
};

export const mergeSites = async (data: IMergeSitesReqDto): Promise<ISite> => {
    const response = await API.fetch(`/sites/merge`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    // if error.status < 500
    if (respBody.error) {
        throw respBody.error;
    }

    throw new Error(`Cannot merge sites!`);
};

export const fetchAllMeters = async ({ include }: IFetchPageQuery, signal?: AbortSignal | null): Promise<IMeter[]> => {
    const pageSize = 10000;

    const meters: IMeter[] = [];
    const pageList = [1];

    for await (let page of pageList) {
        const { data, meta } = await fetchMeters({ include, pagination: { pageSize, current: page } }, signal);

        if (page * pageSize < meta.total) {
            pageList.push(++page);
        }

        meters.push(...data);
    }

    // sort Meters by attached to a Site and Meter name
    return orderBy(
        meters,
        [
            (meter: IMeter) => !!meter.site_id, // sort by attached site
            (meter: IMeter) => meter.name, // then by meter name
        ],
        ['asc', 'asc']
    );
};

export const fetchMeters = async (
    { pagination, sorter, search, include }: IFetchPageQuery,
    signal?: AbortSignal | null
): Promise<IPagination<IMeter>> => {
    const limit = pagination?.pageSize || 1000;
    const current = pagination?.current || 1;
    const offset = (current - 1) * limit;

    if (sorter && (!sorter.field || !sorter.order)) {
        sorter = {};
    }

    const path = `/sites/meters?${new URLSearchParams({
        ...(sorter?.field && { sortField: sorter.field, sortOrder: sorter.order }),
        ...(search && { search }),
        offset: offset.toString(),
        limit: limit.toString(),
        ...(include && { include }),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(response.statusText);
};
