import { TableColumnsType, TableColumnType } from 'antd/lib';
import App from 'antd/lib/app';
import Card from 'antd/lib/card/Card';
import Divider from 'antd/lib/divider';
import Table from 'antd/lib/table';
import Typography from 'antd/lib/typography';
import { format } from 'date-fns';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { ReadingsAvailabilityStatsByEnrollmentTableTitle } from '../../components/readings-availability-stats/ReadingsAvailabilityStatsByEnrollmentTableTitle';
import { ReadingsAvailabilityStatsBySingleCompanyTable } from '../../components/readings-availability-stats/ReadingsAvailabilityStatsBySingleCompanyTable';
import {
    buildEnrollmentIdFilter,
    getColumnFilteredValue,
    getColumnSortOrder,
} from '../../components/table/columnFormatHelpers';
import { WithEmptyDataTable } from '../../components/table/withEmptyDataTable';
import { DEFAULT_PAGINATION } from '../../domain/commonConst';
import {
    buildMonthlyReadingsAvailabilityFilter,
    TKeyofDataAvailabilityPercentagesMap,
} from '../../domain/readings-availability-stats/buildMonthlyReadingsAvailabilityFilter';
import { getReadingsStatsCellColor } from '../../domain/readings-availability-stats/getReadingsStatsCellColor';
import {
    DataQualityReportPeriod,
    IGroupedReadingsAvailabilityStat,
} from '../../domain/readings-availability-stats/interface';
import { useGroupedReadingsAvailabilityStatListQuery } from '../../domain/readings-availability-stats/queries';
import { useReadingsAvailabilityStatsContext } from '../../domain/readings-availability-stats/ReadingsAvailabilityStatsContext';
import {
    filterReadingsAvailabilityStatsByEnrollmentId,
    filterReadingsAvailabilityStatsByMonthPercentRange,
} from '../../domain/readings-availability-stats/tableHelpers';
import { toUsefulParams } from '../toUsefulParams';
import { usePageLocation } from '../usePageState';

interface IPageState {
    period?: DataQualityReportPeriod;
    companyId?: number;
    pagination?: {
        pageSize: number;
        current: number;
    };
    filter?: {
        enrollmentId?: string | number | (string | number)[];
        stats?: {
            [key: string]: number | string[];
        };
    };
    sorter?: {
        field: string;
        order: 'ascend' | 'descend';
    };
}

export const ReadingsAvailabilityStatsByEnrollment = () => {
    const { notification } = App.useApp();
    const location = useLocation();
    const { setPageQuery, queryToState } = usePageLocation();
    const pageState: IPageState = queryToState(location.search);
    const { startDate, endDate, company, period } = useReadingsAvailabilityStatsContext();

    const {
        data = [],
        isLoading,
        isFetching,
        isError,
        error,
    } = useGroupedReadingsAvailabilityStatListQuery(
        {
            startDate,
            endDate,
            period,
            companyId: company?.company_id,
        },
        {
            disable: !company,
        }
    );

    if (isError) {
        notification.error({
            key: 'fetch-readings-availability-stats',
            message: error.message || 'Cannot fetch Data Quality Report!',
        });
    }

    const updatedAt = data?.[0]?.updatedAt || null;
    const enrollmentIds = data.map(record => record.enrollmentId!);

    const columns = useGetTableColumns(pageState, enrollmentIds);

    const readingsAvailabilityStats = useMemo(() => {
        let filteredData = data;
        let filter = pageState.filter || {};

        if (filter.enrollmentId) {
            filteredData = filterReadingsAvailabilityStatsByEnrollmentId(filteredData, filter.enrollmentId);
        }

        if (filter.stats) {
            Object.entries(filter.stats).forEach(([monthKey, rangeKey]) => {
                filteredData = filterReadingsAvailabilityStatsByMonthPercentRange(
                    filteredData,
                    monthKey,
                    rangeKey as TKeyofDataAvailabilityPercentagesMap | TKeyofDataAvailabilityPercentagesMap[]
                );
            });
        }

        return filteredData;
    }, [data, pageState]);

    async function onTableChange(pagination: any, filter: any, sorter: any) {
        const preparedParams = toUsefulParams({ pagination, filter, sorter });

        setPageQuery({ ...pageState, ...preparedParams });
    }

    return (
        <Card size="small" loading={isLoading}>
            <ReadingsAvailabilityStatsByEnrollmentTableTitle
                period={period}
                updatedAt={updatedAt}
                companyName={company?.company_name || ''}
                isLoading={isLoading}
            />

            <ReadingsAvailabilityStatsBySingleCompanyTable />

            <Divider type="horizontal" />

            <WithEmptyDataTable condition={isLoading}>
                <Table<IGroupedReadingsAvailabilityStat>
                    loading={isLoading || isFetching}
                    columns={columns}
                    rowKey={record => record.enrollmentId!}
                    scroll={{ x: 'max-content' }}
                    tableLayout="fixed"
                    onChange={onTableChange}
                    pagination={{
                        pageSize: pageState.pagination?.pageSize || DEFAULT_PAGINATION.pageSize,
                        current: pageState.pagination?.current || DEFAULT_PAGINATION.current,
                        total: readingsAvailabilityStats.length,
                        showSizeChanger: true,
                    }}
                    dataSource={readingsAvailabilityStats}
                />
            </WithEmptyDataTable>
        </Card>
    );
};

function useGetTableColumns(
    pageState: IPageState,
    enrollmentIds: string[]
): TableColumnsType<IGroupedReadingsAvailabilityStat> {
    const { months } = useReadingsAvailabilityStatsContext();

    return [
        {
            title: 'Enrollment ID',
            dataIndex: 'enrollmentId',
            key: 'enrollmentId',
            fixed: 'left',
            width: 200,
            render: (value: string, record) => (
                <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: 200 }}>
                    {record.enrollmentId}
                </Typography.Text>
            ),
            filterMultiple: true,
            filterSearch: true,
            filters: buildEnrollmentIdFilter(enrollmentIds),
            ...getColumnFilteredValue('enrollmentId', pageState.filter),
            sorter: (a, b) => a.enrollmentId!.toLocaleLowerCase().localeCompare(b.enrollmentId!.toLocaleLowerCase()),
            ...getColumnSortOrder('enrollmentId', pageState.sorter),
        },

        {
            title: 'Nomination KW',
            dataIndex: 'nominationKw',
            key: 'nominationKw',
            align: 'center',
            width: 130,
            render: (value: string, record) => record.nominationKw || '-',
            sorter: (a, b) => (a.nominationKw || 0) - (b.nominationKw || 0),
            ...getColumnSortOrder('nominationKw', pageState.sorter),
        },

        ...months.map<TableColumnType<IGroupedReadingsAvailabilityStat>>(month => {
            const monthKey = format(month, 'yyyy-MM-01');
            const monthTitle = format(month, 'MMM-yyyy');

            return {
                title: monthTitle,
                key: `stats.${monthKey}`,
                align: 'center',

                width: 130,

                filters: buildMonthlyReadingsAvailabilityFilter(),
                filterMultiple: true,
                ...getColumnFilteredValue(`stats.${monthKey}`, pageState.filter),

                dataIndex: `stats.${monthKey}`,
                sorter: (a, b) => a.stats[monthKey] - b.stats[monthKey],
                ...getColumnSortOrder(`stats.${monthKey}`, pageState.sorter),

                render: (_, record) => record.stats[monthKey] || '0',
                onCell: record => ({
                    style: {
                        fontSize: '0.8rem',
                        fontWeight: 'bold',
                        background: getReadingsStatsCellColor(record.stats[monthKey]),
                    },
                }),
            };
        }),
    ];
}
