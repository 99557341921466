import React, { useMemo } from 'react';
import Typography from 'antd/lib/typography';
import { defaultDateFormatInUTC } from '../../domain/common/dateFormatters';
import {
    DataQualityReportPeriod,
    DataQualityReportPeriodMap,
} from '../../domain/readings-availability-stats/interface';
import { SECONDARY_COLOR } from '../../theme';

interface ITableTitleProps {
    period: DataQualityReportPeriod;
    updatedAt: string | null;
}

export const ReadingsAvailabilityStatsByCompanyTableTitle: React.FC<ITableTitleProps> = ({ period, updatedAt }) => {
    const periodTitle = DataQualityReportPeriodMap[period];
    const formattedDate = useMemo(() => {
        const date = updatedAt ? new Date(updatedAt) : new Date();
        return defaultDateFormatInUTC(date, new Date().toISOString(), 'dd/MMM/yyyy hh:mm a');
    }, [updatedAt]);

    return (
        <Typography.Title level={4} style={{ marginTop: 0, color: SECONDARY_COLOR, textAlign: 'center' }}>
            <span style={{ color: 'black' }}>{periodTitle}</span> Report of KW interval %
            <br />
            <Typography.Text style={{ marginTop: 0, color: SECONDARY_COLOR, textAlign: 'center' }}>
                Report last processed {formattedDate} (UTC)
            </Typography.Text>
        </Typography.Title>
    );
};
