import { subHours, isBefore, endOfDay, endOfWeek } from 'date-fns';

export const isPastDate = (selectedDate: Date) => {
    const targetDate = subHours(new Date(), 24);
    return isBefore(new Date(selectedDate), targetDate);
};

export const toUsefulEventParams = (state: any) => ({
    ...(state.program ?? {}),
    ...(state.programId && { programId: state.programId }),
    ...(state.companyId && { companyId: state.companyId }),
    ...(state.search && { search: state.search }),
    ...(state.calendarDates && { start: new Date(state.calendarDates.start), end: new Date(state.calendarDates.end) }),
    ...(state.load_zone && { load_zone: state.load_zone }),
    ...(state.market && { market: state.market }),
});

export const prepareCalendarDates = (calendarView: string, startDate: Date, endDate: Date) => {
    const dateRange = { start: +startDate };
    switch (calendarView) {
        case 'resourceTimelineDay':
            return { ...dateRange, end: +endOfDay(startDate) };
        case 'resourceTimelineWeek':
            return { ...dateRange, end: +endOfWeek(startDate) };
        case 'dayGridMonth':
            return { ...dateRange, end: +endOfDay(endDate) };
        default:
            return { ...dateRange, end: +endDate };
    }
};
