import { max, maxBy, mean, round } from 'lodash';
import { endOfDay, startOfDay } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import { Market } from 'src/domain/market-prices/interface';
import { usePeakLoadQuery } from 'src/domain/peak-load-forecast/queries';
import { EChartsOption } from 'echarts';
import { calculateTooltipPosition, CHART_DATA_COLOR, ChartSettings, formatChartTooltip, makeChartAreasSeries, makeLineSeries, mapDataToOneDayRange, peakLoadToSeriesData } from './chart';
import { IPeakDays, IPeakEventIndex } from 'src/domain/peak-load-forecast/interface';
import { PlfChart } from './PlfChart';
import { InfoIcon } from '../InfoIcon';
import './Chart.css';

/**
 * Here we receive all available top peak days
 * and the selected date prop specifies what days to display on the chart
 * we receive here all days as their order is important - order sets color of chart data
 * 
 * So we expect the first peak day will be from year = currently selected year - 1
 * and so on
 */
type PeakDayItem = IPeakDays & { selected: boolean; };

type PeakLoadChartProps = {
    date: Date;
    market: Market;
    loadZone: string;
    timezone: string;
    settings: ChartSettings;
    topPeakDays: PeakDayItem[];
    dayEventIndex: IPeakEventIndex[];
}

export const PeakLoadChart = ({ date, market, loadZone, timezone, settings, topPeakDays, dayEventIndex }: PeakLoadChartProps) => {
    const start = fromZonedTime(startOfDay(date), timezone);
    const end = fromZonedTime(endOfDay(date), timezone);

    const {
        isFetching,
        data,
    } = usePeakLoadQuery({ start, end, market, loadZone }, {
        keepPreviousData: false,
        staleTime: 1 * 60 * 1000
    });

    const areaSeries = makeChartAreasSeries(settings);

    const dataForecastSeries = makeLineSeries(
        'Forecasted',
        peakLoadToSeriesData(data?.forecast),
        { color: CHART_DATA_COLOR, type: 'dashed' },
        start, end,
        settings.timeframe
    );

    const dataActualSeries = makeLineSeries(
        'Actual',
        peakLoadToSeriesData(data?.history),
        { color: CHART_DATA_COLOR, type: 'solid' },
        start, end,
        settings.timeframe
    );

    const topPeakDaysSeries = makeLineSeriesForTopPeakDays(topPeakDays, start, end, settings.timeframe);

    const loadMwAtEventIndex100 = calculateLoadMwAtEventIndex100(topPeakDays);
    const eventIndex100Series = makeLineSeriesForEventIndex100(loadMwAtEventIndex100);

    const series: EChartsOption['series'] = [
        ...eventIndex100Series,
        ...areaSeries,
        ...dataForecastSeries,
        ...dataActualSeries,
        ...topPeakDaysSeries
    ];

    const option: EChartsOption = {
        tooltip: {
            trigger: 'axis',
            padding: 0,
            borderColor: '#ffffff',
            axisPointer: {
                type: 'line',
                lineStyle: {
                    type: 'solid'
                }
            },
            position: (point, params, dom, rect, size) => calculateTooltipPosition(point, size.contentSize),
            formatter: (params) => formatChartTooltip(params, timezone, ' MW', undefined, loadMwAtEventIndex100),
        },
        yAxis: {
            type: 'value',
            max: (value) => {
                const maxValue = max([value.max, loadMwAtEventIndex100])!;
                return maxValue * 1.1
            },
            axisLabel: {
                color: '#697475',
                showMinLabel: false,
                formatter: (value: number) => {
                    return `${new Intl.NumberFormat('en-EN').format(value)} MW`
                }
            },
            axisLine: {
                show: true,
                lineStyle: {
                    color: '#d6dbdb',
                    width: 2,
                    type: 'solid'
                },
            },
            axisPointer: {
                snap: true
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false
            }
        },
        series: series
    };

    return (
        <div className='peak-load-forecast-base-chart peak-load-chart'>
            <div className='peak-load-forecast-chart-title'>
                {loadZone.toUpperCase()} Load
                <InfoIcon
                    tooltip='Forecasted and actual load in the selected zone'
                />
            </div>
            <PlfChart
                style={{ height: '520px', width: '100%' }}
                showLoading={isFetching}
                option={option}
            />
            <div className='chart-timezone'>{timezone}</div>
        </div>
    );
}

function makeLineSeriesForTopPeakDays(topPeakDays: PeakDayItem[], start: Date, end: Date, timeframe: [number, number]) {
    let topPeakDaysSeries: EChartsOption['series'] = [];

    if (!topPeakDays?.length) return [];

    const styleMap: { color: string; type: 'solid' | 'dashed' }[] = [];
    styleMap[0] = { color: '#4b0082', type: 'solid' };
    styleMap[1] = { color: '#48c774', type: 'solid' };
    styleMap[2] = { color: '#ff8c00', type: 'solid' };

    topPeakDays.forEach((day, index) => {
        if (day.selected === false) return;

        const data = mapDataToOneDayRange(
            peakLoadToSeriesData(day.data),
            start
        );

        const style = styleMap[(index % 3)];

        const series = makeLineSeries(
            'TopPeakDay',
            data,
            style,
            start, end,
            timeframe
        );

        (topPeakDaysSeries as any[]).push(...series);
    });

    return topPeakDaysSeries;
}

function calculateLoadMwAtEventIndex100(topPeakDays: PeakDayItem[]) {
    let loadMwAtEventIndex100 = 0;

    const maxLoadItems = topPeakDays.map(item => {
        const peakHour = maxBy(item.data, 'loadMw')!;
        return peakHour.loadMw
    });

    loadMwAtEventIndex100 = round(mean(maxLoadItems));

    return loadMwAtEventIndex100;
}

function makeLineSeriesForEventIndex100(loadMwAtEventIndex100: number) {
    let result: EChartsOption['series'] = [];
    if (loadMwAtEventIndex100 === 0) return result;

    result = [
        {
            name: 'EventIndex100',
            type: 'line',
            showSymbol: false,
            markLine: {
                lineStyle: {
                    color: '#d3e1f8',
                    width: 2,
                    type: 'dashed',
                },
                label: {
                    show: false
                },
                data: [
                    {
                        yAxis: loadMwAtEventIndex100,
                    },
                ],
            },
            labelLine: {
                show: false
            }
        },
    ];

    return result;
}