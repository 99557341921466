import { formatDistance } from 'date-fns';

import Popover from 'antd/lib/popover';
import Typography from 'antd/lib/typography';

import CloudOutlined from '@ant-design/icons/CloudOutlined';
import RobotOutlined from '@ant-design/icons/RobotOutlined';
import WarningFilled from '@ant-design/icons/WarningFilled';

import { CircleIcon } from '../icons/icons';
import { ICloudVen, ICustomerVen, statusColorMap } from '../../domain/ven/interface';
const { Text } = Typography;

export interface IVenIconProps {
    ven: ICustomerVen | ICloudVen;
}

export function VenIcon({ ven }: IVenIconProps) {
    const iconStyle = { fontSize: '28px', color: statusColorMap[ven.status] };

    let icon, title;
    const lastSeenString = ven.ven_date_online
        ? formatDistance(new Date(ven.ven_date_online), new Date(), { addSuffix: true, includeSeconds: true })
        : null;

    if ('company_id' in ven) {
        if (ven.mac_address) {
            title = 'Enersponse Hardware';
            icon = <RobotOutlined style={iconStyle} />;
        } else {
            title = 'Customer VEN';
            icon = <CircleIcon style={iconStyle} />;
        }
    } else {
        title = 'Cloud VEN';
        icon = <CloudOutlined style={iconStyle} />;
    }

    let error: any = null;
    if (ven.ven_error) {
        error = <WarningFilled style={{ fontSize: '14px', color: '#faad14' }} />;
    }

    const statusContent = (
        <div style={{ maxWidth: '200px' }}>
            <div className="device-icon-popover-status">
                {ven.status === 'online' ? (
                    <Text type="success">{ven.status?.toUpperCase()}</Text>
                ) : (
                    <Text type="danger">{ven.status?.toUpperCase()}</Text>
                )}
            </div>
            {ven.ven_error && <Text mark>{ven.ven_error}</Text>}
            {lastSeenString && (
                <div className="device-icon-popover-lastseen">
                    <span>Last seen:</span> &nbsp; {lastSeenString}
                </div>
            )}
        </div>
    );

    const content = (
        <div className="device-icon-popover">
            <div className="device-icon-popover-title">{title}</div>
            {statusContent}
        </div>
    );

    return (
        <>
            {title ? <Popover content={content}>{icon}</Popover> : icon}
            {error ?? <></>}
        </>
    );
}
