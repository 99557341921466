import { format } from 'date-fns';
import config from '../../config';
import { ICompany } from '../company/interface';
import { ISite } from '../site/interface';
import { ICustomerVen } from './interface';

export function makeCustomerVenForSite(site: ISite & { company: ICompany }): Partial<ICustomerVen> {
    const venId = generateVenId(site);
    const venUsername = venId;
    const venPassword = generateVenPassword(site.site_city);

    const venDto: Partial<ICustomerVen> = {
        company_id: site?.company_id,
        site_id: site.site_id,
        ven_username: venUsername,
        ven_password: venPassword,
        ven_id: venId,
        ven_uri: `${config.HOST}/api/vtn`,
        ven_vtn_id: 'ENERSPONSE_VTN',
        ven_market_context: 'enersponse',
        ven_resource_id: undefined,
        ven_group_id: undefined,
        ven_party_id: undefined,
        ven_registration_id: undefined,
        ven_profile: '2.0a',
        ven_cert: undefined,
        ven_key: undefined,
        ven_default_event_signal: undefined,
        ven_detailed_logging: false,
        ven_enabled: true,
    };

    return venDto;
}

function generateVenId(site: ISite & { company: ICompany }): string {
    const siteStoreNumberOrCity = site?.site_store_number || site.site_city;

    let stateAlphaCode = USA_STATES[site.site_state] || null;
    const state = stateAlphaCode || site.site_state.substring(0, 2);
    const string = `${site.company.company_name}.${state}.${siteStoreNumberOrCity}`.toLowerCase();

    return string.replace(/\s+/g, '').replace('&', 'and');
}

/**
 * password: <yymmdd><lowercase city with substitution > o=0(zero), e=3, i=1
 */
function generateVenPassword(city: string): string {
    const encryptCity = (city: string) => {
        return city.toLowerCase().replace(/\s+/g, '').replaceAll('o', '0').replaceAll('e', '3').replaceAll('i', '1');
    };

    const date = format(new Date(), 'yyMMdd');
    const encryptedCity = encryptCity(city);
    return `${date}${encryptedCity}`;
}

const USA_STATES: Record<string, string> = {
    Alabama: 'AL',
    Alaska: 'AK',
    'American Samoa': 'AS',
    Arizona: 'AZ',
    Arkansas: 'AR',
    California: 'CA',
    Colorado: 'CO',
    Connecticut: 'CT',
    Delaware: 'DE',
    'District Of Columbia': 'DC',
    'Federated States Of Micronesia': 'FM',
    Florida: 'FL',
    Georgia: 'GA',
    Guam: 'GU',
    Hawaii: 'HI',
    Idaho: 'ID',
    Illinois: 'IL',
    Indiana: 'IN',
    Iowa: 'IA',
    Kansas: 'KS',
    Kentucky: 'KY',
    Louisiana: 'LA',
    Maine: 'ME',
    'Marshall Islands': 'MH',
    Maryland: 'MD',
    Massachusetts: 'MA',
    Michigan: 'MI',
    Minnesota: 'MN',
    Mississippi: 'MS',
    Missouri: 'MO',
    Montana: 'MT',
    Nebraska: 'NE',
    Nevada: 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    'Northern Mariana Islands': 'MP',
    Ohio: 'OH',
    Oklahoma: 'OK',
    Oregon: 'OR',
    Palau: 'PW',
    Pennsylvania: 'PA',
    'Puerto Rico': 'PR',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    Tennessee: 'TN',
    Texas: 'TX',
    Utah: 'UT',
    Vermont: 'VT',
    'Virgin Islands': 'VI',
    Virginia: 'VA',
    Washington: 'WA',
    'West Virginia': 'WV',
    Wisconsin: 'WI',
    Wyoming: 'WY',
};
