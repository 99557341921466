export interface ICaisoSync {
    id: string;
    date: Date;
    source: string;
    status: string;
    error_message?: string;
}

export enum CaisoSyncOrigin {
    locations = 'locations',
    registrations = 'registrations',
}
