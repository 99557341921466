import App from 'antd/lib/app';
import { InfoCircleOutlined } from '@ant-design/icons';
import ProfileOutlined from '@ant-design/icons/ProfileOutlined';
import { ColumnsType } from 'antd/es/table';
import Tooltip from 'antd/es/tooltip';
import { theme, Typography, Table } from 'antd/lib';
import React, { useEffect, useState } from 'react';
import { MemoizedDatetimePopover } from '../../../components/datetimePopover/datetimePopover';
import { ActionList } from '../../../components/table/actionList/actionList';
import { WithEmptyDataTable } from '../../../components/table/withEmptyDataTable';
import { DEFAULT_PAGINATION } from '../../../domain/commonConst';
import { IUnknownVenError, UNKNOWN_VEN_ERROR_ACTION } from '../../../domain/unknown-ven-error/interface';
import {
    UNKNOWN_VEN_ERROR_LOG_VISIBLE_MINUTES,
    useUnknownVenErrorsListQuery,
} from '../../../domain/unknown-ven-error/queries';
import { IPageState } from '../../interface';
import { toUsefulParams } from '../../toUsefulParams';
import { UnknownVenErrorContextModal } from './UnknownVenErrorContextModal';
import { toTablePagination } from '../../../components/table/toTablePagination';

export const defaultState = {
    loading: false,
    pagination: DEFAULT_PAGINATION,
    sorter: {
        field: 'date_modified',
        order: 'descend',
    },
    filter: {},
};

export const UnknownVenErrorsTable: React.FC = () => {
    const { token: themeColors } = theme.useToken();

    const { notification } = App.useApp();

    const [action, setAction] = useState<UNKNOWN_VEN_ERROR_ACTION | null>(null);
    const [unknownVenError, setUnknownVenError] = useState<IUnknownVenError | null>(null);

    const [state, setState] = useState<IPageState>(defaultState as IPageState);
    const [tableData, setTableData] = useState<any>({
        data: [],
        meta: { current: 1, pageSize: state.pagination.pageSize, total: 0 },
    });

    const preparedParams = toUsefulParams(state);
    const unknownVenErrorsListQuery = useUnknownVenErrorsListQuery(
        { pagination: preparedParams.pagination },
        { enabled: true }
    );

    if (unknownVenErrorsListQuery.isError) {
        notification.error({
            key: 'fetch-unknown-ven-errors-error',
            message: unknownVenErrorsListQuery.error.message || 'Cannot fetch Customer VEN errors data!',
        });
    }

    useEffect(() => {
        if (unknownVenErrorsListQuery.data) {
            const pagination = toTablePagination(unknownVenErrorsListQuery.data.meta);
            setTableData({ data: unknownVenErrorsListQuery.data.data, meta: pagination });
        }
    }, [unknownVenErrorsListQuery.data]);

    async function onTableChange(pagination: any, filter: any, sorter: any) {
        const preparedParams = toUsefulParams({ pagination, filter, sorter });

        setState({ ...state, ...preparedParams });
    }

    const showSiteActionModal = (record: IUnknownVenError, action: UNKNOWN_VEN_ERROR_ACTION) => () => {
        setAction(action);
        setUnknownVenError({ ...record });
    };

    function onUnknownVenErrorContextModalClose() {
        setUnknownVenError(null);
        setAction(null);
    }

    const actions = [
        {
            title: 'View Context',
            onClick: (record: IUnknownVenError) => showSiteActionModal(record, UNKNOWN_VEN_ERROR_ACTION.VIEW_CONTEXT),
            icon: <ProfileOutlined />,
        },
    ];

    const columns: ColumnsType<IUnknownVenError> = [
        {
            title: 'Date',
            dataIndex: ['date_modified'],
            render: (text: string) => (
                <MemoizedDatetimePopover timestamp={new Date(text).valueOf()} dateFormat="dd/MMM/yyyy hh:mm a" />
            ),
        },
        {
            title: 'Username',
            dataIndex: ['context', 'username'],
            render: (text: string) => text || '-',
        },
        {
            title: 'IP',
            dataIndex: ['ip'],
        },
        {
            title: 'Error',
            dataIndex: ['error_message'],
        },
        {
            key: 'action',
            title: 'Context',
            sorter: false,
            width: 100,
            render: (text: string, record: any) => <ActionList alwaysShow actions={actions} item={record} />,
        },
    ];

    return (
        <>
            <Typography.Title level={5} style={{ paddingTop: '8px', textAlign: 'center' }}>
                Unknown Ven Errors
                <Tooltip
                    title={`This table displays errors only for last ${UNKNOWN_VEN_ERROR_LOG_VISIBLE_MINUTES} minutes`}
                >
                    <InfoCircleOutlined
                        style={{ color: themeColors.colorPrimary, fontSize: '16px', paddingLeft: '8px' }}
                    />
                </Tooltip>
            </Typography.Title>

            <WithEmptyDataTable condition={unknownVenErrorsListQuery.isLoading}>
                <Table
                    size="small"
                    rowKey="id"
                    sticky
                    pagination={{
                        ...tableData.meta,
                        showSizeChanger: true,
                        size: 'default',
                        hideOnSinglePage: true,
                    }}
                    columns={columns}
                    dataSource={tableData.data}
                    loading={unknownVenErrorsListQuery.isLoading}
                    onChange={onTableChange}
                />
            </WithEmptyDataTable>

            {unknownVenError && action === UNKNOWN_VEN_ERROR_ACTION.VIEW_CONTEXT && (
                <UnknownVenErrorContextModal
                    onClose={onUnknownVenErrorContextModalClose}
                    unknownVenError={unknownVenError}
                />
            )}
        </>
    );
};
