import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useCompanies } from 'src/components/selectors/GlobalCompanySelector/CompanyContext';
import { useAuth } from 'src/domain/auth/useAuth';
import { AbilityContext } from 'src/components/ability/can';

export function Caiso() {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const currentView = (pathname.split('/')[2]) || 'sibr';  // Default view to 'sibr'
    const auth = useAuth()!;
    const ability = useContext(AbilityContext);
    const { companyId, companies } = useCompanies()!;
    const companyQuery = companyId ? `?companyId=${companyId}` : '';

    const [view, setView] = useState(currentView);

    useEffect(() => {
        setView(currentView);
    }, [currentView]);

    useEffect(() => {
        if (pathname === '/Caiso' || pathname === '/Caiso/') {
            navigate(`/caiso/sibr/overview${companyQuery}`);
        }
    }, [pathname, navigate, companyQuery]);

    const handleViewMode = (newView: string, subView?: string) => {
        let path = `/caiso/${newView}`;
        if (subView) {
            path += `/${subView}`;
        }
        path += companyQuery;
        navigate(path);
    };

    return (
        <>
            <Outlet
                context={{
                    view,
                    handleViewMode,
                    auth,
                    ability,
                    companyId,
                    companies,
                }}
            />
        </>
    );
}