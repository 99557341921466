import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../query-client';
import { fetchPrices, fetchRealTimePrice } from './index';
import { IFetchMarketZonePriceQuery, IMarketZonePrice, IRealTimeNodesPrice, IRealTimeZonesPrice } from './interface';

export const useRealTimePriceListQuery = (options = {}) => {
    return useQuery<(IRealTimeZonesPrice | IRealTimeNodesPrice)[], Error>({
        queryKey: [QueryKey.REAL_TIME_PRICES],
        queryFn: ({ signal }) => fetchRealTimePrice(signal),
        ...options,
    });
};

export const usePriceListQuery = (query: IFetchMarketZonePriceQuery, options = {}) => {
    return useQuery<IMarketZonePrice[], Error>({
        queryKey: [QueryKey.MARKET_PRICES, query],
        queryFn: ({ signal }) => fetchPrices(query, signal),
        ...options,
    });
};
