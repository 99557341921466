import { ReactNode } from 'react';

import Button from 'antd/lib/button';
import Tooltip from 'antd/lib/tooltip';

import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';

import './actionList.css';

export interface IAction {
    icon?: ReactNode | Function;
    title: string | Function;
    onClick: any;
    dataAction?: string;
}

export interface IActionList {
    item: any;
    actions: IAction[];
    alwaysShow?: boolean;
    children?: any;
}

interface IRenderIcon {
    item: any;
    icon?: ReactNode | Function;
    title: string;
}

const ICON: { [key: string]: ReactNode } = {
    Edit: <EditOutlined />,
    Delete: <DeleteOutlined />,
    Info: <InfoCircleOutlined />,
};

const renderIcon = ({ icon, title, item }: IRenderIcon) => {
    let iconToRender = icon;
    if (typeof icon === 'function') {
        iconToRender = icon(item);
    }
    const actionIcon = ICON[title];
    return icon ? iconToRender : actionIcon;
};

export const ActionList = ({ item, actions, alwaysShow, children }: IActionList) => (
    <div data-cy="action-block" className={alwaysShow ? '' : 'action'}>
        {children}
        {actions.map(action => {
            let actionTitle = action.title;
            if (typeof action.title === 'function') {
                actionTitle = action.title(item);
            }
            return (
                <Tooltip title={actionTitle as ReactNode} key={`${item.site_id}-${actionTitle}`}>
                    <Button
                        type="text"
                        data-action={`${action.dataAction || actionTitle}`}
                        onClick={action.onClick(item)}
                        icon={
                            (renderIcon({ ...action, title: actionTitle as string, item }) as ReactNode) || (
                                <EditOutlined />
                            )
                        }
                    />
                </Tooltip>
            );
        })}
    </div>
);
