import { Divider, Tooltip } from 'antd';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import Space from 'antd/lib/space';
import Typography from 'antd/lib/typography';
import { theme } from 'antd/lib';
import { formatInTimeZone } from 'date-fns-tz';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import { DataZoomComponent, GridComponent, MarkAreaComponent, TooltipComponent } from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import isNil from 'lodash/isNil';
import { useMemo } from 'react';
import { currencyUSDFormatter } from '../../domain/common/currencyFormatters';
import { ISavingsMetrics } from '../../domain/price-response/interface';
import { ContentBlock } from '../ContentBlock/ContentBlock';
import { ReactComponent as MoneyIcon } from '../icons/money.svg';

echarts.use([LineChart, TooltipComponent, GridComponent, CanvasRenderer, MarkAreaComponent, DataZoomComponent]);

interface ISavingsWidget {
    savings: ISavingsMetrics;
}

const prepareSavingsData = (
    savingsData: {
        date: string;
        value: number;
    }[]
) => {
    let savingsValue: number[] = [];
    let months: string[] = [];
    savingsData.forEach((data, i) => {
        const month = formatInTimeZone(new Date(data.date), 'UTC', 'LLL');
        savingsValue.push(data.value);
        months.push(month);
    });
    return { savingsValue, months };
};

export const SavingsWidget = ({ savings }: ISavingsWidget) => {
    const { token: themeColors } = theme.useToken();
    const totalSavings = useMemo(() => {
        let res = 0;
        savings.total.forEach(s => (res += s.value));
        return res;
    }, [savings]);
    const preparedSavingsData = useMemo(() => prepareSavingsData(savings.total), [savings]);

    const option = {
        legend: {},
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                label: {
                    show: true,
                },
            },
            formatter: (params: any) =>
                !isNil(params[0].value) ? `${currencyUSDFormatter.format(params[0].value)}` : '',
        },

        yAxis: {
            type: 'value',
            splitLine: {
                show: true,
            },
        },
        xAxis: {
            type: 'category',
            axisTick: { show: false },
            data: preparedSavingsData.months,
        },
        series: [
            {
                type: 'line',
                emphasis: { focus: 'series' },
                data: preparedSavingsData.savingsValue,
                itemStyle: {
                    color: themeColors.colorPrimary,
                },
            },
        ],
    };

    return (
        <Row
            justify="space-between"
            gutter={[0, 0]}
            className="content-background"
            style={{ padding: '32px 8px', borderRadius: 8 }}
        >
            <Col xs={24} md={11}>
                <div className="content-row">
                    <Row align="middle" justify="center">
                        <Tooltip title="Savings are based on your performance during the event ">
                            <Space align="center">
                                <MoneyIcon style={{ width: '50px', height: '50px', fill: themeColors.colorPrimary }} />
                                <Typography.Title
                                    level={4}
                                    style={{ color: themeColors.colorPrimary, fontSize: '25px', margin: 0 }}
                                >
                                    Savings
                                </Typography.Title>
                            </Space>
                        </Tooltip>
                    </Row>
                    <ContentBlock
                        value={currencyUSDFormatter.format(totalSavings)}
                        description="Total USD Saved"
                        descriptionClass="main-content-description"
                        valueClass="main-content-value"
                    />
                </div>
            </Col>
            <Col xs={0} md={2} style={{ display: 'flex', justifyContent: 'center' }}>
                <Divider type="vertical" style={{ height: '100%' }} />
            </Col>
            <Col xs={24} md={0}>
                <Divider style={{ height: '100%' }} />
            </Col>
            <Col xs={24} md={11}>
                <div className="content-row">
                    <ContentBlock
                        value={currencyUSDFormatter.format(savings.avgPerEvent)}
                        description="Average savings/event"
                        descriptionClass="content-description"
                        valueClass="content-value"
                    />
                    <ContentBlock
                        value={currencyUSDFormatter.format(savings.avgPerSite)}
                        description="Average savings/site"
                        descriptionClass="content-description"
                        valueClass="content-value"
                    />
                </div>
            </Col>
            <Col span={24}>
                <ReactEChartsCore echarts={echarts} option={option} notMerge={true} lazyUpdate={true} />
            </Col>
        </Row>
    );
};
