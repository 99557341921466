import { useState, useEffect, useMemo } from 'react';
import { LOCAL_STORAGE_PREFIX } from './commonConst';

export function useLocalStorage<T>(key: string, initialValue: T) {
    const localStorageKey = useMemo(() => `${LOCAL_STORAGE_PREFIX}.${key}`, [key]);

    // Get value from local storage or fallback to initialValue
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            const item = window.localStorage.getItem(localStorageKey);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.error(error);
            return initialValue;
        }
    });

    // Update local storage whenever storedValue changes
    useEffect(() => {
        try {
            window.localStorage.setItem(localStorageKey, JSON.stringify(storedValue));
        } catch (error) {
            console.error(error);
        }
    }, [localStorageKey, storedValue]);

    // Function to set the stored value
    const setValue = (value: T | ((val: T) => T)) => {
        try {
            // Allow value to be a function to mimic useState's API
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
        } catch (error) {
            console.error(error);
        }
    };

    return [storedValue, setValue] as const;
}
