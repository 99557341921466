import { FC } from 'react';
import { EChartsOption } from 'echarts';
import { Market } from 'src/domain/market-prices/interface';
import { fromZonedTime } from 'date-fns-tz';
import { endOfDay, startOfDay } from 'date-fns';
import { usePeakLoadQuery } from 'src/domain/peak-load-forecast/queries';
import { calculateTooltipPosition, CHART_DATA_COLOR, ChartSettings, formatChartTooltip, makeChartAreasSeries, makeLineSeries, peakLoadToSeriesData } from './chart';
import { PlfChart } from './PlfChart';
import './Chart.css';

type SystemLoadChartProps = {
    date: Date;
    market: Market;
    loadZone: string;
    timezone: string;
    settings: ChartSettings;
}

export const SystemLoadChart: FC<SystemLoadChartProps> = ({ date, market, loadZone, timezone, settings }) => {

    const start = fromZonedTime(startOfDay(date), timezone);
    const end = fromZonedTime(endOfDay(date), timezone);

    const {
        isFetching,
        data,
    } = usePeakLoadQuery({ start, end, market, loadZone }, {
        keepPreviousData: true,
        staleTime: 1 * 60 * 1000 // 1h 
    });

    const areaSeries = makeChartAreasSeries(settings);

    const dataForecastSeries = makeLineSeries(
        'Forecasted',
        peakLoadToSeriesData(data?.forecast),
        { color: CHART_DATA_COLOR, type: 'dashed' },
        start, end,
        settings.timeframe
    );

    const dataActualSeries = makeLineSeries(
        'Actual',
        peakLoadToSeriesData(data?.history),
        { color: CHART_DATA_COLOR, type: 'solid' },
        start, end,
        settings.timeframe
    );

    const series: EChartsOption['series'] = [
        ...areaSeries,
        ...dataForecastSeries,
        ...dataActualSeries
    ];

    const option: EChartsOption = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'line',
                lineStyle: {
                    type: 'solid'
                }
            },
            position: (point, params, dom, rect, size) => calculateTooltipPosition(point, size.contentSize),
            formatter: (params) => formatChartTooltip(params, timezone, ' MW')
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: (value: number) => {
                    return `${new Intl.NumberFormat('en-EN').format(value)} MW`
                },
            },
        },
        series: series
    };

    return (
        <div className='peak-load-forecast-base-chart'>
            <div className='peak-load-forecast-chart-title'>
                System Load
            </div>
            <PlfChart
                style={{ height: '320px', width: '100%' }}
                showLoading={isFetching}
                option={option}
            />
            <div className='chart-timezone'>{timezone}</div>
        </div>
    );
}