import { ITrackingAlert } from '../domain/event/interface';
import Tooltip from 'antd/lib/tooltip';
import { format } from 'date-fns';
import CheckOutlined from '@ant-design/icons/CheckOutlined';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import { ERROR_COLOR, SUCCESS_COLOR } from '../theme';

export const TrackingAlertStatusIcon = ({ trackingAlert }: { trackingAlert: ITrackingAlert }) => {
    if (trackingAlert.confirmedAt) {
        return (
            <Tooltip title={`Confirmed at ${format(new Date(trackingAlert.confirmedAt), 'hh:mm a MMM dd, yyyy')}`}>
                <CheckOutlined style={{ color: SUCCESS_COLOR }} />
            </Tooltip>
        );
    }

    if (trackingAlert.rejectedAt) {
        return (
            <Tooltip title={`Rejected at ${format(new Date(trackingAlert.rejectedAt), 'hh:mm a MMM dd, yyyy')}`}>
                <CloseOutlined style={{ color: ERROR_COLOR }} />
            </Tooltip>
        );
    }

    return null;
};
