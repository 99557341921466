import App from 'antd/lib/app';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '../../../query-client';
import { IFetchCompanyBrandingQuery, IUpdateCompanyBranding } from './interface';
import { fetchCompanyBranding, updateCompanyBranding } from './index';

export const useCompanyBrandingQuery = (query: IFetchCompanyBrandingQuery, options = {}) => {
    return useQuery({
        queryKey: [QueryKey.COMPANY_BRANDING, query],
        queryFn: ({ signal }) => fetchCompanyBranding(query, signal),
        ...options,
    });
};

export const useCompanyBrandingUpdateMutation = () => {
    const { notification } = App.useApp();
    const queryClient = useQueryClient();

    return useMutation<
        void,
        Error,
        {
            companyId: number;
            companyBranding: IUpdateCompanyBranding;
        }
    >(updateCompanyBranding, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([QueryKey.COMPANY_BRANDING]);
            notification.success({
                key: 'company-branding-update-success',
                message: 'Company branding updated successfully',
            });
        },
        onError: async error => {
            notification.error({
                key: 'company-branding-update-error',
                message: error.message || 'Cannot update company branding',
            });
        },
    });
};
