import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import set from 'lodash/set';
import { DEFAULT_PAGINATION } from '../domain/commonConst';

type PageState = Record<string, any>;

export const flattenObject = (obj: any, prefix = '') => {
    const result: { [key: string]: any } = {};
    Object.keys(obj)
        .filter(key => obj[key] !== null)
        .forEach(element => {
            const pre = prefix.length ? prefix + '.' : '';
            const isArray = Array.isArray(obj[element]);
            if (typeof obj[element] === 'object' && !isArray) {
                Object.assign(result, flattenObject(obj[element], pre + element));
            } else {
                result[pre + element] = obj[element];
            }
        });
    return result;
};

export const unflattenObject = (obj: any) => {
    const result = {};
    Object.keys(obj).forEach(key => {
        let path = key.split('.');
        set(result, path, obj[key]);
    });
    return result;
};

const parsePageStateQuery = (query: string) => {
    const parseQuery = new URLSearchParams(query);
    const searchValue = parseQuery.get('search');

    return {
        ...queryString.parse(query, { arrayFormat: 'comma', parseNumbers: true }),
        ...(searchValue && { search: searchValue }),
    };
};

export const usePageLocation = <T extends PageState = Readonly<PageState>>() => {
    const location = useLocation();
    const navigation = useNavigate();

    const queryToState = (query: string) => {
        return unflattenObject(parsePageStateQuery(query)) as T;
    };

    const prepareNewPageState = (newPageState: T, currentPageState: T) => {
        let preparedState = { ...newPageState };
        const defaultPagination = { pageSize: DEFAULT_PAGINATION.pageSize, current: DEFAULT_PAGINATION.current };
        // when companyId change we should skip pagination to default and reset selected sites
        if (newPageState.companyId !== currentPageState.companyId) {
            preparedState = {
                ...preparedState,
                ...(preparedState?.pagination && { pagination: defaultPagination }),
                ...(preparedState?.siteId && { siteId: null }),
            };
        }
        // when search value change we should skip pagination to default
        if (newPageState.search !== currentPageState.search) {
            preparedState = {
                ...preparedState,
                ...(preparedState?.pagination && { pagination: defaultPagination }),
            };
        }
        //when status (ven status) value change we should skip pagination to default
        if (newPageState.status !== currentPageState.status) {
            preparedState = {
                ...preparedState,
                ...(preparedState?.pagination && { pagination: defaultPagination }),
            };
        }

        //when type (ven type) value change we should skip pagination to default
        if (newPageState.type !== currentPageState.type) {
            preparedState = {
                ...preparedState,
                ...(preparedState?.pagination && { pagination: defaultPagination }),
            };
        }

        return preparedState;
    };

    const setPageQuery = (newPageState: T) => {
        const currentPageState = queryToState(location.search);
        const preparedNewPageState = prepareNewPageState(newPageState, currentPageState);

        const searchString = queryString.stringify(flattenObject(preparedNewPageState), {
            arrayFormat: 'comma',
            skipNull: true,
            skipEmptyString: true,
        });
        navigation(
            {
                ...location,
                search: searchString,
            },
            { replace: true }
        );
    };

    const pageQuery = queryToState(location.search);

    return {
        pageQuery,
        setPageQuery,
        queryToState,
    };
};
