import { useCallback, useState } from 'react';

export enum FORM_MODE {
    NEW = 'new',
    EDIT = 'edit',
    DELETE = 'delete',
    INFO = 'info',
}

export const useFormMode = () => {
    const [formMode, setFormMode] = useState<FORM_MODE | ''>('');
    const onSetFormMode = useCallback((newValue: FORM_MODE | '') => setFormMode(newValue), []);
    const isEditMode = formMode === FORM_MODE.EDIT;

    return { formMode, onSetFormMode, isEditMode };
};
