import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart } from 'echarts/charts';
import {
    TooltipComponent,
    GridComponent,
    MarkAreaComponent,
    DataZoomComponent,
    LegendScrollComponent,
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { useMemo } from 'react';
import Empty from 'antd/lib/empty';
import { getMonth } from 'date-fns';
import { theme } from 'antd/lib';
import sum from 'lodash/sum';
import { IMetricsByPeriod } from '../../../domain/impact/interface';
import { MONTHS } from '../../../domain/commonConst';
import { compact } from 'lodash';

echarts.use([
    BarChart,
    TooltipComponent,
    GridComponent,
    CanvasRenderer,
    MarkAreaComponent,
    DataZoomComponent,
    LegendScrollComponent,
]);

interface ICumulativeBarChart {
    data: IMetricsByPeriod;
    timestamp: {
        start: number;
        end: number;
    };
}

const prepareData = (data: IMetricsByPeriod, timestamp: ICumulativeBarChart['timestamp']) => {
    let co2Values: (number | undefined)[] = [];
    let cumulativeData: (number | undefined)[] = [];
    let months: string[] = [];

    const timestampYear = new Date(timestamp.end).getFullYear();
    const timestampMonth = new Date(timestamp.end).getMonth();

    const isCurrentYear = timestampYear === new Date().getFullYear();
    const currentMonth = getMonth(new Date());
    const upToMonth = currentMonth < timestampMonth ? currentMonth : timestampMonth;

    Object.entries(data).forEach(([month, metrics], i) => {
        const co2Impact = metrics?.co2Impact!;
        const monthIndex = Number(month) - 1;
        if (isCurrentYear && monthIndex > upToMonth) {
            cumulativeData[i] = undefined;
        } else {
            cumulativeData[i] = i === 0 ? co2Impact : co2Impact + (cumulativeData[i - 1] || 0);
        }

        months.push(MONTHS[monthIndex]);
        co2Values.push(co2Impact || undefined);
    });

    return { months, preparedData: co2Values, cumulativeData };
};

export const CumulativeBarChart = ({ data, timestamp }: ICumulativeBarChart) => {
    const { token: themeColors } = theme.useToken();

    const { months, preparedData, cumulativeData } = useMemo(() => prepareData(data, timestamp), [data, timestamp]);
    const isDataExist = useMemo(() => preparedData.some(val => val), [preparedData]);
    const maxValue = Math.max(...compact(preparedData));
    const isMoreThanMillion = sum(cumulativeData) >= 1e6;
    const dataUom = isMoreThanMillion ? '(millions)' : '';

    const option = {
        legend: {},
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                label: {
                    show: true,
                },
            },
        },
        grid: {
            right: '5.5%',
        },
        xAxis: {
            type: 'category',
            axisTick: { show: false },
            data: months,
        },
        yAxis: {
            type: 'value',
            name: `Pounds of CO2 Avoided ${dataUom}`,
            nameLocation: 'middle',
            nameGap: maxValue >= 10_000 ? 58 : 45,
            splitLine: {
                show: true,
            },
            axisLabel: {
                formatter: function (value: number) {
                    value = +value;
                    return (isFinite(value) && (isMoreThanMillion ? value / 1_000_000 : value)) || '';
                },
                showMinLabel: true,
                hideOverlap: true,
                overflow: 'truncate',
            },
        },
        series: [
            {
                name: 'Monthly Avoided CO2',
                type: 'bar',
                barMaxWidth: '30px',
                data: preparedData,
                barMinHeight: 2,
                itemStyle: {
                    color: themeColors.colorTextSecondary,
                },
            },
            {
                name: 'Cumulative Avoided CO2',
                type: 'bar',
                large: true,
                barMaxWidth: '30px',
                barMinHeight: 2,
                data: cumulativeData,
                itemStyle: {
                    color: themeColors.colorPrimary,
                },
            },
        ],
    };
    return isDataExist ? (
        <ReactEChartsCore echarts={echarts} option={option} notMerge={true} lazyUpdate={true} />
    ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    );
};
