import React, { useEffect, useState } from 'react';
import { Card, List, Typography, Dropdown } from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    StarOutlined,
    EllipsisOutlined,
    StarFilled,
    LoadingOutlined,
} from '@ant-design/icons';
import { isEmpty } from 'lodash';
import './sidebarSavedFilter.css';
import { API } from 'src/domain/api';
import App from 'antd/lib/app';
import { useSidebar } from '../context/SidebarContext';
import { SidebarSavedFilterDeleteModal } from './SidebarSavedFilterDeleteModal/SidebarSavedFilterDeleteModal';
import { usePageLocation } from 'src/pages/usePageState';
import { useLocation } from 'react-router-dom';

const { Text } = Typography;

const allowedPageState = ['companyId'];

export interface OptionsDto {
    control_provider_id?: number;
    exist_salesforce_meter_enrollments?: boolean;
    exist_salesforce_meters?: boolean;
    interval_data?: string;
    site_is_closed?: boolean;
    lmp_market?: string;
    utility_customer_id?: number;
    program_id?: string;
    site_utility?: string;
}
export interface SiteSavedFilterDto {
    id: number;
    user_id: number;
    user_name: string;
    filter_values: OptionsDto;
    description?: string;
    status: string;
    type: string;
    sub_type?: string;
    filter_name: string;
    modified_by_user_name: string;
    date_created: Date;
    date_modified: Date;
    display_in_dashboard: boolean;
}

interface ISavedTabProps {
    openModal: (item: SiteSavedFilterDto) => void;
    triggerFetchAll: number;
}

const SavedTab: React.FC<ISavedTabProps> = ({ openModal, triggerFetchAll }) => {
    const location = useLocation();
    const { setPageQuery, queryToState } = usePageLocation();
    const pageState = queryToState(location.search);
    const filteredPageState = allowedPageState.reduce(
        (obj, key) => {
            if (pageState[key] !== undefined) {
                obj[key] = pageState[key];
            }
            return obj;
        },
        {} as Record<string, any>
    );

    const [currentFilter, setCurrentFilter] = useState<SiteSavedFilterDto>();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingFilterId, setLoadingFilterId] = useState<number | null>(null);

    const { selectedFilterId, setSelectedFilterId } = useSidebar();

    const { fetchAllSavedFilters, savedFilters, getSelectedFilterFromItemToDisplay, toggleDashboardDisplay } =
        useSidebar();

    const { notification } = App.useApp();

    useEffect(() => {
        fetchAllSavedFilters();
    }, [triggerFetchAll]);

    const handleMenuClick = (e: { key: string }, item: SiteSavedFilterDto) => {
        if (e.key === 'edit') {
            openModal(item);
        } else if (e.key === 'delete') {
            setCurrentFilter(item);
            setOpenDeleteModal(true);
        }
    };

    const handleSelectSavedFilter = (filter: SiteSavedFilterDto) => {
        const filters = getSelectedFilterFromItemToDisplay(filter);
        let newFilters = {};
        filters.forEach(item => {
            let newFilter = { [`filter.${item.key}`]: item.value };
            newFilters = { ...newFilters, ...newFilter };
        });
        setPageQuery({ ...filteredPageState, ...newFilters });
        setSelectedFilterId(filter.id); // Set the selected filter ID
    };

    const handleToggleDashboard = async (filter: SiteSavedFilterDto) => {
        setLoadingFilterId(filter.id);
        try {
            await toggleDashboardDisplay(filter.id);
            notification.success({
                message: 'Success',
                description: `The filter "${filter.filter_name}" was ${filter.display_in_dashboard ? 'removed from' : 'added to'} the dashboard.`,
            });
        } catch (err) {
            notification.error({
                message: 'Error',
                description: `Failed to ${filter.display_in_dashboard ? 'remove' : 'add'} the filter "${filter.filter_name}" to the dashboard.`,
            });
        } finally {
            setLoadingFilterId(null);
        }
    };

    // Define menu items for the Dropdown
    const menuItems = {
        items: [
            {
                key: 'edit',
                label: 'Edit',
                icon: <EditOutlined />,
            },
            {
                key: 'delete',
                label: 'Delete',
                icon: <DeleteOutlined style={{ color: 'red' }} />,
            },
        ],
    };

    const handleCloseDeleteModal = () => {
        setCurrentFilter(undefined);
        setOpenDeleteModal(false);
    };

    const handleDeleteFilter = async (filter: SiteSavedFilterDto) => {
        try {
            setIsLoading(true);
            const response = await API.fetch(`/filters/${filter.id}`, { method: 'DELETE' });

            if (response.status === 404) {
                notification.success({
                    message: 'Filter Removed',
                    description: `"${filter.filter_name}" filter removed. `,
                });
                setIsLoading(false);
                setOpenDeleteModal(false);
                setCurrentFilter(undefined);
                fetchAllSavedFilters();
            }
        } catch (err) {
            setIsLoading(false);
            notification.error({ message: 'Filter Removed', description: `Please try again in some minutes.` });
            console.log(err);
        }
    };

    return (
        <div className="saved-tab-container">
            <List
                dataSource={savedFilters}
                renderItem={item => (
                    <Card
                        className={`saved-item-card ${selectedFilterId === item.id ? 'selected' : ''}`} // Add 'selected' class if the item is selected
                        bordered={false}
                        onClick={() => handleSelectSavedFilter(item)}
                    >
                        <div className="saved-item-header">
                            <div>
                                <Text>{item.filter_name}</Text>
                            </div>
                            <div>
                                {loadingFilterId === item.id ? (
                                    <LoadingOutlined className="saved-item-icon" />
                                ) : item.display_in_dashboard ? (
                                    <StarFilled
                                        className="saved-item-icon-gold"
                                        onClick={() => handleToggleDashboard(item)}
                                    />
                                ) : (
                                    <StarOutlined
                                        className="saved-item-icon"
                                        onClick={() => handleToggleDashboard(item)}
                                    />
                                )}
                                <Dropdown
                                    menu={{
                                        items: menuItems.items,
                                        onClick: event => {
                                            handleMenuClick(event, item);
                                        },
                                    }}
                                    trigger={['click']}
                                    placement="bottomRight"
                                >
                                    <EllipsisOutlined
                                        className="saved-item-options"
                                        onClick={e => e.stopPropagation()}
                                    />
                                </Dropdown>
                            </div>
                        </div>
                        <Text type="secondary">{isEmpty(item.description) ? '-' : item.description}</Text>
                        {/* <div className="saved-item-footer">
                            <Text type="secondary">{`${0} Results`}</Text>
                            {0 ? <Text className="saved-item-footer-results">{`${0} New`}</Text> : null}
                        </div> */}
                    </Card>
                )}
            />
            <SidebarSavedFilterDeleteModal
                open={openDeleteModal}
                filter={currentFilter}
                onClose={handleCloseDeleteModal}
                onSubmit={handleDeleteFilter}
                isLoading={isLoading}
            />
        </div>
    );
};

export default SavedTab;
