import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FC, useEffect } from 'react';
import { add, format, startOfMonth } from 'date-fns';
import { formatInTimeZone, fromZonedTime } from 'date-fns-tz';
import classNames from 'classnames';
import { orderBy } from 'lodash';
import { usePeakDaysQuery } from 'src/domain/peak-load-forecast/queries';
import { Market } from 'src/domain/market-prices/interface';
import { IPeakDays } from 'src/domain/peak-load-forecast/interface';
import './TopPeakDays.css';

export type PeakDayState = IPeakDays & { selected: boolean };

type PeakDaySelectedState = [boolean, boolean, boolean];

type TopPeakDaysProps = {
    selectedDay: Date;
    selectedState: PeakDaySelectedState;
    market: Market;
    loadZone: string;
    timezone: string;
    onChange: (val: PeakDayState[]) => void;
}

export const TopPeakDays: FC<TopPeakDaysProps> = ({ selectedDay, selectedState, market, loadZone, timezone, onChange }) => {
    const datetime = fromZonedTime(startOfMonth(selectedDay), timezone);

    const state: PeakDaySelectedState = [!!selectedState[0], !!selectedState[1], !!selectedState[2]];

    const {
        isFetching,
        data,
    } = usePeakDaysQuery({ datetime, market, loadZone }, {
        keepPreviousData: false,
        staleTime: 1 * 60 * 1000 // 1h
    });

    const days: { value: Date, selected: boolean }[] = [];
    const sortedData = orderBy(data, ['peakDay'], ['desc']);

    if (sortedData?.length) {
        sortedData.forEach((item, index) => {
            days.push({ value: new Date(item.peakDay), selected: !!state[index] })
        })
    }

    useEffect(() => {
        if (isFetching === false) {
            const result = createPeakDayState(sortedData, state);
            onChange(result);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching, JSON.stringify(sortedData)]);

    function onPeakDaysChange(date: Date, e: CheckboxChangeEvent) {
        const newState = { ...state };
        const index = days.findIndex(day => day.value.toISOString() === date.toISOString());

        if (index >= 0 && index <= 2) {
            newState[index] = e.target.checked
        }

        const result = createPeakDayState(sortedData, newState);
        onChange(result);
    }

    function formatPeakDay(day: Date) {
        const hourEnding = add(day, { hours: 1 });
        return `${formatInTimeZone(hourEnding, timezone, 'yyyy/MM/dd cccc ')} HE ${formatInTimeZone(hourEnding, timezone, 'HH')}`;
    }

    if (!days?.length) {
        return <></>;
    }

    return (
        <div className='top-peak-days'>
            <p style={{ margin: '0 0 10px 0' }}>Top three peak days over the past 3 years in {format(selectedDay, 'MMMM')}:</p>
            {days.map((day, index) => {

                const dayClass = classNames({
                    'first-day': index === 0,
                    'second-day': index === 1,
                    'third-day': index === 2
                });

                return (
                    <Checkbox
                        key={'top-peak-day-' + index}
                        checked={!!days[index].selected}
                        className={dayClass}
                        onChange={(e) => onPeakDaysChange(day.value, e)}
                    >
                        {formatPeakDay(day.value)}
                    </Checkbox>
                )
            })}
        </div>
    )
}

function createPeakDayState(sortedData: IPeakDays[], state: PeakDaySelectedState) {
    const result: PeakDayState[] = sortedData?.map((item, index) => {
        return {
            ...item,
            selected: !!state[index]
        };
    }) || [];

    return result;
}