import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import { API } from '../api';
import { formatToISOString } from '../common/dateFormatters';
import { IFetchPriceResponseReportingQuery, IPriceResponseMetric } from './interface';

export const fetchPriceResponseReportingData = async (
    { start, end, companyId }: IFetchPriceResponseReportingQuery,
    signal?: AbortSignal | null
): Promise<IPriceResponseMetric> => {
    const path = `/reporting/price-response?${new URLSearchParams({
        companyId: companyId.toString(),
        start: formatToISOString(start),
        end: formatToISOString(end),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody = await response.json();

    if (response.ok) {
        return respBody;
    }
    throw new Error(respBody?.error?.message || `Cannot load price response metrics data!`);
};

export const exportPriceResponse = async ({ start, end, companyId }: IFetchPriceResponseReportingQuery) => {
    const path = `/reporting/price-response?${new URLSearchParams({
        ...(companyId && { companyId: companyId.toString() }),
        start: formatToISOString(start),
        end: formatToISOString(end),
        format: 'pdf',
    })}`;

    const response = await API.fetch(path, {
        headers: {
            'Content-Type': 'application/pdf',
        },
    });

    const respBody: any = await response.blob();
    if (response.ok) {
        const blob = new Blob([respBody], { type: 'application/pdf' });
        saveAs(blob, `PriceResponseReporting_${format(start, 'MM-dd-yyyy')}-${format(end, 'MM-dd-yyyy')}.pdf`);
    } else {
        const respBody: any = await response.json();
        throw new Error(respBody?.error?.message || `Cannot export data in pdf!`);
    }
};

export const activatePriceResponseImpact = async () => {
    const response = await API.fetch(`/reporting/price-response/impact/activate`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    const respBody: any = await response.json();
    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot activate!`);
};
