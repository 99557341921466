import React, { useState, useEffect } from 'react';
import { Cascader } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';

interface BreadcrumbOption {
    value: string;
    label: string;
    children?: BreadcrumbOption[];
}

export const CaisoBreadcrumbNav = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [selectedValue, setSelectedValue] = useState<string[]>([]);

    const breadcrumbOptions: BreadcrumbOption[] = [
        {
            value: 'caiso',
            label: 'CAISO',
            children: [
                {
                    value: 'sibr',
                    label: 'SIBR',
                    children: [
                        { value: 'overview', label: 'Overview' },
                        { value: 'configuration', label: 'Configuration' },
                    ],
                },
                {
                    value: 'drrs',
                    label: 'DRRS',
                    children: [
                        { value: 'locations', label: 'Locations' },
                        { value: 'registrations', label: 'Registrations' },
                    ],
                },
            ],
        },
    ];

    useEffect(() => {
        const pathParts = pathname.split('/').filter((part) => part);
        setSelectedValue(pathParts);
    }, [pathname]);

    const handleBreadcrumbChange = (value: string[]) => {
        const [caiso, view, subView] = value;

        let path = `/caiso/${view}`;
        if (subView) {
            path += `/${subView}`;
        }

        navigate(path);
    };

    return (
        <div>
            <Cascader
                options={breadcrumbOptions}
                onChange={handleBreadcrumbChange}
                value={selectedValue}
                placeholder="Select navigation"
                style={{ marginBottom: '16px', width: '300px' }}
            />
        </div>
    );
};