import { endOfMonth, startOfMonth } from 'date-fns';
import { IDateRange } from '../../pages/interface';
import { dateToUtcLocale } from '../common/timeHelpers';

export function prepareEventReqDateParams(date: IDateRange) {
    const dateNow = new Date();
    const startDate = date.start ? new Date(+date.start) : startOfMonth(dateNow);
    const endDate = date.end ? new Date(+date.end) : endOfMonth(dateNow);

    return {
        start: dateToUtcLocale(startDate),
        end: dateToUtcLocale(endDate),
    };
}
